import React, { useContext } from 'react';
import styles from './PoliceSFTP.styles.js';
import { Input, Divider, Select } from 'antd'; // Importamos Input de Ant Design
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import { AppContext } from 'App.context.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import usePoliceReportSFTPConfigHook from './PoliceSFTP.hook.js';

export default function PoliceSFTP() {
  const { state, actions } = usePoliceReportSFTPConfigHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpHost')}</b>
          <Input
            bordered={false}
            value={state.ftpHost || ''}
            onChange={(e) => actions.setField('ftpHost', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>
        <div style={styles.port}>
          <b style={styles.header}>{translate('policeReport.ftpPort')}</b>
          <Input
            bordered={false}
            value={state.ftpPort || ''}
            onChange={(e) => actions.setField('ftpPort', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>
      </div>

      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpUser')}</b>
          <Input
            bordered={false}
            value={state.ftpUser || ''}
            onChange={(e) => actions.setField('ftpUser', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpPass')}</b>
          <Input.Password
            bordered={false}
            value={state.ftpPass || ''}
            onChange={(e) => actions.setField('ftpPass', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
            visibilityToggle={actions.isAdmin()} // cant see info of field forn non admin users
          />
        </div>
      </div>

      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpFilePrefix')}</b>
          <Input
            bordered={false}
            value={state.filePrefix || ''}
            onChange={(e) => actions.setField('filePrefix', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>

        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.fileFormat')}</b>
          <Select
            defaultValue="YYYY-MM-DD"
            bordered={false}
            value={state.fileFormat || "YYYY-MM-DD"}
            style={{ backgroundColor: '#f5f5f5', width: '100%' }}
            onChange={(value) => actions.setField('fileFormat', value)}
            options={[
              { value: 'YYYY-MM-DD', label:  "YYYY-MM-DD"},
              { value: 'YYYYMMDD', label:  "YYYYMMDD"},
              { value: 'DD-MM-YYYY', label:  "DD-MM-YYYY"},
              { value: 'DDMMYYYY', label:  "DDMMYYYY"},
            ]}
            disabled={state.isLoading}
          />
        </div>

        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpFileSufix')}</b>
          <Input
            bordered={false}
            value={state.fileSufix || ''}
            onChange={(e) => actions.setField('fileSufix', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>

        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.extension')}</b>
          <Select
            bordered={false}
            value={state.extension || "txt"}
            style={{ backgroundColor: '#f5f5f5', width: '100%' }}
            onChange={(value) => actions.setField('extension', value)}
            options={[
              { value: 'txt', label:  "txt"},
              { value: 'xml', label:  "xml"},
            ]}
            disabled={state.isLoading}
          />
        </div>
      </div>
    

      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.completeFileName')}</b>
          <Input
            bordered={false}
            value={state.filePrefix + state.fileFormat + state.fileSufix + state.extension}
            disabled={true}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>
      </div>

      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.dateSelector.title')}</b>
          <div></div>
          <Select
            defaultValue="yesterday"
            bordered={false}
            value={state.dateSelected || "yesterday"}
            style={{ backgroundColor: '#f5f5f5', width: '100%' }}
            onChange={(value) => actions.setField('dateSelected', value)}
            options={[
              { value: 'yesterday', label: translate('policeReport.dateSelector.yesterday') },
              { value: 'today', label: translate('policeReport.dateSelector.today') },
            ]}
            disabled={state.isLoading}
          />

        </div>
      </div>
      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.ftpDirPath')}</b>
          <Input
            bordered={false}
            value={state.ftpDirPath || ''}
            onChange={(e) => actions.setField('ftpDirPath', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>
      </div>

      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.testFile')}</b>
          <Input
            bordered={false}
            value={state.testFile || ''}
            onChange={(e) => actions.setField('testFile', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: '#f5f5f5' }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '22px',
            marginBottom: '16px',
          }}
        >
          <AppButton
            text={translate('policeReport.validateFileExport')}
            secondary
            action={() => actions.validateFileExport()}
            disabled={state.isLoading}
          />
        </div>
      </div>

      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <AppButton
          text={translate('policeReport.checkConnection')}
          secondary
          action={() => actions.onCheckConnection()}
          disabled={state.isLoading}
        />

        <AppButton
          text={translate('policeReport.save')}
          action={() => actions.onSaveFTPConfig()}
          disabled={state.isLoading}
        />
      </div>

      {state.ftpErrors.length === 0 && state.isValidation && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>
            {translate('policeReport.ftpResult')}
          </Divider>
          <div style={styles.validateSFTPWithoutErrors}>
            {translate('policeReport.validateSFTPWithoutErrors')}
          </div>
        </div>
      )}

      {state?.ftpErrors?.length > 0 && state.isValidation && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>{translate('policeReport.ftpFiles')}</Divider>
          <div style={styles.credentialsContainer}>
            <ul>
              {state.ftpErrors.map((file, index) => (
                <li
                  key={index}
                  style={file.error === 'error' ? { color: 'red', fontWeight: 'bold' } : {}}
                >
                  {file.message}
                </li>
              ))}
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('policeReport.explainRedColorFileValidation').replace(
                    /\{(.*?)\}/g,
                    '<span style="color: red; font-weight: bold;">$1</span>'
                  ),
                }}
              />
            </ul>
          </div>
        </div>
      )}

      {state?.ftpFiles?.length > 0 && state?.isFileList && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>{translate('policeReport.ftpFiles')}</Divider>
          <div style={styles.credentialsContainer}>
            <ul>
              {state.ftpFiles.map((file, index) => (
                <li
                  key={index}>
                  {file}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {state.fileContent && state.fileContent.length > 0 && (
        <div>
          <Divider style={{ borderColor: '#009ae9' }}>{translate('policeReport.ftpFileContent')}</Divider>
          <div style={styles.credentialsContainer}>{state.fileContent}</div>
        </div>
      )}
    </div>
  );
}
