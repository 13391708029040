import React, { useContext } from 'react';
import styles from './PoliceReportList.styles.js';
import usePoliceReportListHook from './PoliceReportList.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import BaseTable from 'Components/common/BaseTable/BaseTable.jsx';
import PoliceReportCaption from '../PoliceReportCaption/PoliceReportCaption.jsx';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator.jsx';
import { capitalize } from 'utils/TextUtils.js';
import { Button, DatePicker, Alert, Table, Divider, Descriptions, Badge, List } from 'antd';
import { EyeOutlined, CloseSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import Colors from 'constants/Colors.js';
import Loading from 'Components/common/Loader/Loading.jsx';
import constants from '../../../../utils/constants';
import ITAgetColumns from './Config/ITAPoliceReportList.config';
import SESgetColumns from './Config/SESPoliceReportList.config';
import AppModal from 'Components/common/AppModal/AppModal.jsx';

const { RangePicker } = DatePicker;

export default function PoliceReportList() {
  const { state, actions } = usePoliceReportListHook();
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  let stateRow;
  if (state.platform === constants.POLICE_REPORT_PLATFORMS.SES) {
  stateRow = {
    title: translate('policeReport.statusCol'),
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filterMultiple: false,
    filters: [
      { text: 'Ok', value: 'ok' },
      { text: 'Error', value: 'error' },
      { text: 'Pending', value: 'pending' },
    ],
    defaultFilteredValue: state.filters?.status ? [state.filters?.status] : [],

    render: (status, object) => {
      const statusText =
        status === 'error'
          ? actions.parseError(object.error, object) ||
            translate(`policeReport.ses.status.${object.status}`)
          : translate(`policeReport.ses.status.${object.status}`);
      return (
        <div style={styles.statusContainer}>
          <DotIndicator color={actions.getColorForStatus(object.status)} />
          <div>{statusText}</div>
        </div>
      );
    },
  };
}else{ // MOSSOS ERTZ uses same traductions change if needed
  stateRow = {
    title: translate('policeReport.statusCol'),
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filterMultiple: false,
    filters: [
      { text: 'Ok', value: 'ok' },
      { text: 'Error', value: 'error' },
      { text: 'Pending', value: 'pending' },
    ],
    defaultFilteredValue: state.filters?.status ? [state.filters?.status] : [],

    render: (status, object) => {
      const statusText =
        status === 'error'
          ? actions.parseError(object.error, object) ||
            translate(`policeReport.ertz.status.${object.status}`)
          : translate(`policeReport.ertz.status.${object.status}`);
      return (
        <div style={styles.statusContainer}>
          <DotIndicator color={actions.getColorForStatus(object.status)} />
          <div>{statusText}</div>
        </div>
      );
    },
  };
}
  const platform = state.platform;
  let columns;
    switch (platform) {
      case constants.POLICE_REPORT_PLATFORMS.SES:  
      columns = SESgetColumns(translate, actions, state)
      break; 
      case constants.POLICE_REPORT_PLATFORMS.ERTZANTZA:  // Using same for ses
      columns = SESgetColumns(translate, actions, state)
      break;
      case constants.POLICE_REPORT_PLATFORMS.MOSSOS:  // Usin same as ses
      columns = SESgetColumns(translate, actions, state)
      break; 

      // case constants.POLICE_REPORT_PLATFORMS.ITALIAN:
      //   columns = ITAgetColumns(translate, actions, state)
      //   break;


      default:
        columns = SESgetColumns(translate, actions, state)
      
    }

  if (state.enableFilters) {
    columns.unshift(stateRow);
  
  }

  const statusColumns = [
    // needed to form the table not used header 
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: "50%"
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      width: "50%"
    },
  ];

  return (
    <DefaultLayout singleBox title={translate('bookingList.title')} enableAllHotels={false}>
      {state.suscription ? (
        
        
        <div style={{...styles.container}}>

       

        <div style={{...styles.container, padding: 5,}}>

          <div style={{...styles.buttonContainer}}>
            {state.enableFilters ? <PoliceReportCaption platform={state.platform}/> : <div />}
          </div>
          
            <div style={{ ...styles.TextContainer }}>
              <p>{translate('policeReport.statusTable.platform')}:  {translate(`policeReport.${platform}.${platform}`)}</p>
              <p>{translate('policeReport.statusTable.send')}: {translate(`policeReport.statusTable.status.${state.statusSendReport}`)}</p>
              <p>{translate('policeReport.statusTable.interfacePlatform')}: {state.InterfaceType}</p>
              {state.InterfaceType === "SFTP" && state.sendTimestamp && state.fileSearchError && (
                <>
                  <p>{translate('policeReport.statusTable.searchFileTimestamp')}: {state.sendTimestamp}</p>
                  <p>{translate('policeReport.statusTable.fileNotFound')}: {state.fileSearchError}</p>
                </>
              )}
            </div>

            <div style={{ ...styles.header, justifyContent: 'flex-end' }}>
               <AppButton
                text={
                  !state.isForcedPoliceReport
                    ? translate('policeReport.forceDownloadTrigger')
                    : translate('policeReport.forcedDownloadTrigger')
                }
                action={actions.forcePoliceReportDownloadTrigger}
                disabled={
                  state.isForcedPoliceReport || !state.date || state.dataSource?.totalItems > 0 || appState.role === 'guest'
                  || state.isLoading}
              />
              <AppButton
                text={translate('policeReport.downloadAllFilesButton')}
                action={actions.sendGetFiles}
                disabled={state.dataSource?.totalItems === 0 || !state.date || appState.role === 'guest' || state.isLoading}
              />
              <AppButton
                text={translate('policeReport.sendAllErrorsButton')}
                action={actions.sendAllErrors}
                disabled={state.dataSource?.totalItems === 0  || appState.role === 'guest' || state.isLoading}
              />
              {appState.superUser && (
              <AppButton
                disabled={state.isLoading || state.dataSource?.totalItems === 0}
                action={() => actions.onDeleteAllDayClicked()}
                text={translate('policeReport.sideItem.delete.button')}
                redButton={true}
              />
             )}
              
              {state.isLoadingForcePoliceReport && <Loading size={'small'} color={'white'} />}
              <div style={styles.datePicker}>
                <AppInput
                  value={state.date}
                  onChange={(_, date) => actions.onDateSet(date)}
                  type={AppInputTypes.DATE}
                  disabled={state.isLoading}
                  bgColor={Colors.white}
                />
              </div>
            </div>
          </div>
          {state.ftpErrors.length > 0 && state.isValidation && (
            <div>
              <div style={styles.credentialsContainer}>
                <ul>
                  {state.ftpErrors.map((file, index) => (
                    <li
                      key={index}
                      style={{ color: 'red', fontWeight: 'bold' }}
                    >
                      {file.message}
                    </li>
                  ))}
                  <br />
                  <p style={{ color: 'red', fontWeight: 'bold' }}>{translate('policeReport.noReportsErrorInFileValidation')}</p>
                </ul>
              </div>
            </div>
          )}
          {state.alertMessage &&
            <>
              <Alert style={styles.alertStyle} message={state.alertMessage.text[state.lang]} type={state.alertMessage.type} closable />
            </>}
          <div style={styles.whiteBackground}>
            <BaseTable
              columns={columns}
              data={state.dataSource}
              loading={state.isLoading}
              onChange={filters => actions.onChange(filters)}
              currentPage={state.dataSource.page}
            />

            <AppModal // all day
            show={state.showAllDayDeleteWarning}
            title={translate('policeReport.sideItem.delete.warning.title')}
            description={translate('policeReport.sideItem.delete.warning.description')}
            okText={translate('Ok')}
            okAction={actions.sendDeleteAll}
            cancelAction={() => actions.setShowDeleteAllWarning(false)}
            closable={false}
           />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: Colors.white,
              borderRadius: 16,
              padding: 16,
              maxWidth: 600,
            }}
          >
            <div style={{ fontWeight: 'bold' }}>
              {translate('policeReport.missingSuscription.title')}
            </div>
            <div>{translate('policeReport.missingSuscription.description')}</div>
            {state.sendSuscriptionRequestEnabled && (
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
                <AppButton
                  text={translate('policeReport.missingSuscription.send')}
                  action={() => actions.requestGuestlinkFunctionality()}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  );
}
