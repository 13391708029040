import Colors from "constants/Colors";

export default {
  textContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconModal: {
    margin: '25px 0px',
    backgroundColor: Colors.redDesactivedBg,
    color: Colors.red,
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleModal: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  textModal: {
    fontSize: '13px',
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  activateButton: {
    width: '95%',
    borderRadius: '5px',
    backgroundColor: Colors.blueColor,
    borderColor: Colors.blueColor,
    marginBottom: '10px',
  },
  cancelButton: {
    width: '95%',
    borderRadius: '5px',
  },
};