import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';

const usePaymentConfigurationHook = () => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    hotels: null,
    paymentsList: null,
    selectedHotel: null,
    isLoading: true,
    formInputs: null,
    isLoadingForm: false,
    paymentSelected: [],
    expandedRow: null,
    enabledHotels: [],
    suscription: null,
  });

  useEffect(() => {
    actions.loadHotels();
    actions.loadPaymentsList();
  }, []);

  useEffect(() => {
    actions.getPaymentSuscriptionStatus();
    actions.filterByHotel();
  }, [state.allHotels, appState.selectedHotel]);

  const actions = {
    loadHotels: () => {
      API.pmsHotelsRequest()
        .then(hotelsList => {
          API.getHotelsPaymentSelected().then(res => {
            const enabledHotels = res.map(hotelPayment => {
              if (hotelPayment.platform) {
                return hotelPayment.hotelId;
              }
            });

            const selectedPayments = [];
            for (const hotelPayment of res) {
              selectedPayments[hotelPayment.hotelId] = hotelPayment.platform;
            }
            updateState({
              allHotels: hotelsList,
              enabledHotels,
              paymentSelected: selectedPayments,
            });
          });
        })
        .catch(err => {
          console.warn(err);
        });
    },

    getPaymentSuscriptionStatus: () => {
      if (!getSelectedHotel()?.key) updateState({ suscription: true });
      else {
        API.getPaymentSuscriptionStatus({ hotelId: getSelectedHotel()?.key }).then(res => {
          updateState({ suscription: res });
        });
      }
    },

    loadPaymentsList: () => {
      API.getPaymentsList().then(res => {
        updateState({
          isLoading: false,
          paymentsList: res,
        });
      });
    },

    updateEnabledHotels: () => {
      API.getHotelsPaymentSelected().then(res => {
        const enabledHotels = res.map(hotelPayment => {
          if (hotelPayment.platform) {
            return hotelPayment.hotelId;
          }
        });
        updateState({ enabledHotels });
      });
    },

    getColorStateFromPayment: hotel => {
      if (state.enabledHotels.includes(hotel.id)) {
        return Colors.greenHotelIntegratedText;
      } else {
        return Colors.redIcon;
      }
    },

    getBackgroundColorStateFromPayment: hotel => {
      if (state.enabledHotels.includes(hotel.id)) {
        return Colors.greenHotelIntegratedBg;
      } else {
        return Colors.redDesactivedBg;
      }
    },

    getTextStateFromPayment: hotel => {
      if (state.enabledHotels.includes(hotel.id)) {
        return translate('states.enabled');
      } else {
        return translate('states.disabled');
      }
    },

    setSelectedPayment: (rowID, payment) => {
      const currentlySelected = state.paymentSelected;
      currentlySelected[rowID] = payment;
      updateState({ paymentSelected: currentlySelected });
    },

    getSelectedPayment: rowID => {
      if (state.paymentSelected && state.paymentSelected[rowID]) {
        return state.paymentsList.find(payment => payment.name == state.paymentSelected[rowID]);
      } else {
        return null;
      }
    },

    filterByHotel: () => {
      if (!state.allHotels) return;
      let dataSet;
      if (!getSelectedHotel()?.key) {
        dataSet = state.allHotels;
      } else {
        const filteredHotels = state.allHotels?.filter(
          hotel => hotel.name === getSelectedHotel()?.value
        );
        dataSet = filteredHotels;
      }

      dataSet.forEach(element => {
        element.key = element.id;
      });

      updateState({ hotels: dataSet });
    },

    onExpanded: (rowId, expanded) => {
      if (state.expandedRow === rowId) {
        updateState({ expandedRow: null });
      } else {
        updateState({ expandedRow: rowId });
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default usePaymentConfigurationHook;
