import React, { useContext, useEffect } from 'react';
import { AppContext } from 'App.context.js';
import usePoliceReportSESConfigHook from './PoliceSES.hook';
import { Input } from 'antd';
import Loading from 'Components/common/Loader/Loading.jsx';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import styles from './PoliceSES.styles.js'; 
import Colors from 'constants/Colors'; 

export default function PoliceSES() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  
  const { state, actions } = usePoliceReportSESConfigHook();
  
  useEffect(() => {
    if (!state.isConfigLoaded && !state.isLoading) {
      actions.loadPoliceReportConfig();
    }
  }, [state.isConfigLoaded, state.isLoading, actions]);

  const saveSESConfig = () => {
    actions.onSaveSESConfig();
  };

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.sesUser')}</b>
          <Input
            bordered={false}
            value={state.sesUser || ''}
            onChange={(e) => actions.setField('sesUser', e.target.value.trim())}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.sesPass')}</b>
          <Input.Password
            bordered={false}
            value={state.sesPass || ''}
            onChange={(e) => actions.setField('sesPass', e.target.value.trim())}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
            visibilityToggle={actions.isAdmin()}
          />
        </div>
      </div>
      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.lessorCode')}</b>
          <Input
            bordered={false}
            value={state.lessorCode || ''}
            onChange={(e) => actions.setField('lessorCode', e.target.value.trim())}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.establishmentCode')}</b>
          <Input
            bordered={false}
            value={state.establishmentCode || ''}
            onChange={(e) => actions.setField('establishmentCode', e.target.value.trim())}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div>
      </div>
      <div style={styles.buttonContainer}>
        {state.isLoading && <Loading size={'small'} />}
        <AppButton
          text={translate('policeReport.checkConnection')}
          secondary
          action={() => actions.onCheckSESConnection()}
          disabled={state.isLoading}
        />
        <AppButton
          text={translate('policeReport.save')}
          action={saveSESConfig}
          disabled={state.isLoading}
        />
      </div>
    </div>
  );
}
