import React, { useContext, useEffect, useState } from 'react';
import { Upload, Input } from 'antd'; // Importamos Input de Ant Design
import styles from './PoliceERTZ.styles.js';
import { AppContext } from 'App.context.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import usePoliceReportERTZConfigHook from './PoliceERTZ.hook.js';
import Colors from 'constants/Colors';

export default function PoliceERTZ() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePoliceReportERTZConfigHook();
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [pfxFile, setPfxFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!isConfigLoaded) {
      actions.loadPoliceReportConfig();
      setIsConfigLoaded(true);
    }
  }, [actions, isConfigLoaded]);

  const handleBeforeUpload = (file) => {
    setPfxFile(file);
    setFileList([file]); 
    return false; 
  };

  const handleRemove = () => {
    setPfxFile(null); 
    setFileList([]); 
    actions.deleteFile(); 
  };

  const listFiles = () => {
    const fileName = actions.getFileName();
    if (fileName) {
      setFileList([{ name: fileName, status: 'done' }]);
    } else {
      setFileList([]);
    }
  };

  const saveERTZConfig = () => {
    if (pfxFile) {
      actions.uploadPfxFile(pfxFile);
    }
    actions.onSaveERTZConfig();
  };

  useEffect(() => {
    listFiles(); 
  }, [state.awsKey]);

  return (
    <div>
      <div style={styles.credentialsContainer}>
        {/* <div style={{ ...styles.inputContainer, flex: 1 }}>
          <b style={styles.header}>{translate('policeReport.sesPass')}</b>
          <Input.Password
            bordered={false}
            value={state.sesPass || ''}
            onChange={(e) => actions.setField('sesPass', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
            visibilityToggle={actions.isAdmin()} // cant see info of field for non admin users
          />
        </div> */}
      </div>
      <div style={styles.credentialsContainer}>
        {/* <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.lessorCode')}</b>
          <Input
            bordered={false}
            value={state.lessorCode || ''}
            onChange={(e) => actions.setField('lessorCode', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div> */}
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.establishmentCode')}</b>
          <Input
            bordered={false}  
            value={state.establishmentCode || ''}
            onChange={(e) => actions.setField('establishmentCode', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div>
      </div>
      {/* <div style={styles.buttonContainer}>
        <Upload
          accept=".pfx"
          listType="text"
          maxCount={1}
          showUploadList={{
            showRemoveIcon: true,
          }}
          fileList={fileList}
          beforeUpload={handleBeforeUpload}
          onRemove={handleRemove}
        >
          {!fileList.length && (
            <AppButton secondary text={translate('policeReport.uploadPfx')} />
          )}
        </Upload>
      </div> */}
      <div style={styles.buttonContainer}>
        {/* <AppButton
          text={translate('policeReport.checkConnection')}
          secondary
          action={() => actions.onCheckSESConnection()}
          disabled={state.isLoading}
        /> */}
        <AppButton
          text={translate('policeReport.save')}
          action={saveERTZConfig}
          disabled={state.isLoading}
        />
      </div>
    </div>
  );
}
