import Colors from 'constants/Colors';

export default {
  mainContainer: {
    width: '100%',
    paddingRight: 32,
    paddingLeft: 32,
  },
  header: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
  },
  headerState: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    color: Colors.darkText,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  title: {
    color: Colors.titleColor,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'flex-end',
    width: '100%',
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    marginTop: 16,
    marginBottom: 32,
  },
  table: {
    backgroundColor: 'white',
    marginLeft: 32,
    marginRight: 32,
    width: '100%',
    borderRadius: 14,
    padding: 14,
    minHeight: '70vh',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
};
