import React, { useContext, useState } from 'react';
import { Checkbox, Modal, Button } from 'antd';
import Colors from 'constants/Colors.js';
import GuestFormFieldState from '../../GuestForms/GuestFormFieldState.js';
import DynamicFormsRowType from '../DynamicFormsRowType.js';
import styles from './DynamicFormsValue.styles.js';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AppContext } from 'App.context.js';
import useGuestHook from '../../../PMS/PMSSelector/Guest/hooks/Guest.hook.js';

const { FIELD } = DynamicFormsRowType;

export default function DynamicFormsValue({
  disabled,
  isLastItem,
  isGroup,
  size,
  setFieldValue,
  guestType,
  field,
  rowType,
  value,
  pmsModuleName,
  pmsUdfState,
  supportedFields,
}) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { snakeToCamel } = useGuestHook();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedType, setSelectedType] = useState('');

  const handleCheckboxClick = (value, type) => {
    const fieldName = snakeToCamel(field);
    const normalizedField = fieldName.trim().toLowerCase();
    const pmsName = getPmsName(normalizedField);

    if (!pmsName && shouldShowModal(value, type)) {
      setSelectedValue(value);
      setSelectedType(type); 

      let descriptionText;

      if (['uploadDocument1', 'uploadDocument2'].includes(fieldName)) {
        descriptionText = translate('guestTable.unavailableInPMSDescription');
      } else {
        descriptionText = ['Cloudbeds', 'Cloudbeds_new', 'Opera', 'Ohip'].includes(pmsModuleName)
          ? translate('guestTable.requiresMappingDescription')
          : translate('guestTable.unavailableInPMSDescription');
      }
      
      setModalContent(descriptionText);
      setIsModalVisible(true);
    } else {
      toggleField(value, type);
    }
  };

  const getPmsName = (normalizedField) => {
    const supportedFieldsMap = new Map(
      supportedFields.map(({ civitfun, pms }) => [civitfun.trim().toLowerCase(), pms])
    );
    let pmsName = supportedFieldsMap.get(normalizedField) || '';

    if (pmsUdfState) {
      pmsUdfState.hotelUdfs.forEach(udf => {
        if (snakeToCamel(udf.validate) === normalizedField) {
          pmsName += pmsName ? ' + ' + udf.name : udf.name;
        }
      });
    }
    return pmsName;
  };

  const shouldShowModal = (value, type) => {
    return (type === 'visible' && value.state === GuestFormFieldState.DISABLED) ||
            (type === 'required' && value.required === 0);
  };

  const toggleField = (value, type) => {
    if (type === 'visible') {
      setFieldValue({
        guestType,
        fieldName: field,
        active:
          value.state === GuestFormFieldState.ACTIVE
            ? GuestFormFieldState.DISABLED
            : GuestFormFieldState.ACTIVE,
        rowType,
      });
    } else if (type === 'required') {
      setFieldValue({
        guestType,
        fieldName: field,
        required: value.required === 1 ? 0 : 1,
        rowType,
      });
    }
  };

  return (
    <div
      style={{
        ...styles.container,
        borderLeft: !isGroup ? `1px solid ${Colors.lightGray}` : null,
        borderBottom: !isGroup ? `1px solid ${Colors.lightGray}` : null,
        borderRight: isLastItem ? `1px ${Colors.lightGray} solid` : null,
        borderBottomRightRadius: isLastItem && rowType !== FIELD ? 8 : 0,
        borderTopRightRadius: isLastItem && rowType !== FIELD ? 8 : 0,
        backgroundColor: isGroup ? Colors.dynamicFormColor : Colors.white,
        minWidth: size,
        maxWidth: size,
      }}
    >
      <Checkbox
        checked={value.mandatory || value.state === GuestFormFieldState.ACTIVE}
        disabled={disabled}
        onClick={() => handleCheckboxClick(value, "visible")}
      />
      <Checkbox
        checked={value.mandatory || value.required}
        disabled={disabled}
        onClick={() => handleCheckboxClick(value, "required")}
      />

      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={521}
        height={391}
      >
        <div style={styles.textContainerModal}>
          <div style={styles.iconModal}>
            <ExclamationCircleOutlined />
          </div>
          <div style={styles.titleModal}>
            <strong>Activación de Campo en PMS</strong>
          </div>
          <div style={styles.textModal}>
            <p>{modalContent}</p>
          </div>
        </div>
        <div style={styles.modalButton}>
          <Button
            key="activate"
            type="primary"
            onClick={() => {
              toggleField(selectedValue, selectedType);
              setIsModalVisible(false);
            }}
            style={styles.activateButton}
          >
            Activar Campo
          </Button>
        </div>
        <div style={styles.modalButton}>
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            style={styles.cancelButton}
          >
            Cancelar
          </Button>
        </div>
      </Modal>
    </div>
  );
}
