import React, { useContext } from 'react';
import { AppContext } from '../../../../../App.context';
import { getInputText } from '../../../../../utils/TextUtils';
import styles from './CheckInInput.styles';

export default function CheckInInput({ title, value, discrepancy, supportedField, pmsValue, discrepancyDate }) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  const allowedUserIds = [ 139, 816, 915, 2554, 2715, 2716, 2723, 3095, 3892,
    3898, 4633, 4945, 5086, 5410, 5677, 5757, 5992, 6279, 6281, 6282, 6283,
    6746, 7304, 7323, 7324, 7325, 7326, 7340, 7894, 7959, 8090, 8193,
  ];
  const isAllowedUser = allowedUserIds.includes(appState.userId);
  const hasDiscrepancy = discrepancy || !supportedField;
  const dateOfDiscrepancy = discrepancyDate >= "2025-03-22 00:00:00";

  const canViewDiscrepancy = isAllowedUser && hasDiscrepancy && dateOfDiscrepancy;

  const text = getInputText(value, translate);

  if (!title && value.includes('@')) title = translate('login.emailPlaceholder');

  const inputContainerStyle = canViewDiscrepancy && pmsValue
    ? pmsValue === translate('checkInList.detail.guest.pmsFieldUnavailable')
      ? { ...styles.inputContainer, ...styles.inputContainerFieldUnavailable }
      : { ...styles.inputContainer, ...styles.inputContainerError }
    : styles.inputContainer;

  return value ? (
    <div style={styles.container}>
      <div style={styles.title}>{title}</div>
      <div style={inputContainerStyle}>{text}</div>

      {canViewDiscrepancy && pmsValue && (
        <div style={pmsValue === translate('checkInList.detail.guest.pmsFieldUnavailable') 
          ? styles.pmsFieldUnavailableContainer
          : styles.pmsValueContainer
        }>
          <span>
            {pmsValue === translate('checkInList.detail.guest.pmsFieldUnavailable') 
              ? pmsValue
              : `${translate('checkInList.detail.guest.pmsValue')} ${pmsValue}`
            }
          </span>
        </div>
      )}
    </div>
  ) : null;
}
