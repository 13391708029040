import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { getParsedReports } from '../civitfun-police-report-utils/PoliceReportParse.utils';
import { capitalize } from 'utils/TextUtils';
import FIELDS from '../civitfun-police-report-utils/PoliceReportFields';

export default function usePoliceReportListHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate, setPoliceReportFilters, getSelectedLanguage },
  } = useContext(AppContext);
  const history = useHistory();

  const { API } = useContext(ApiContext);

  const savedPaging = appState.policeReportFilters?.dataSource;

  const [state, setState] = useState({
    isLoading: false,
    dataSource: {
      items: [],
      totalItems: savedPaging?.totalItems || 0,
      totalPages: savedPaging?.totalPages || 0,
      page: savedPaging?.page || 0,
      size: savedPaging?.size || 10,
      reportStatus: [],
    },
    date: appState.policeReportFilters?.date || moment().subtract(1, 'days'),
    filters: appState.policeReportFilters?.filters || null,
    datesRange: getDefaultDateRange(),
    enableFilters: true,
    initialLoad: true,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    isLoadingForcePoliceReport: false,
    isForcedPoliceReport: false,
    host: false,
    username: false,
    password: false,
    port: false,
    folder: false,
    fileFormat: false,
    fileSufix: false,
    extension: false,
    ftpErrors: false,
    alertMessage: false,
    lang: 'es',
    platform: null,
    showAllDayDeleteWarning: false,
    statusTable: [], 
    InterfaceType: null,
    statusSendReport: null,
    sendTimestamp: null,
    fileSearchError: null,
  });

  useEffect(() => {
    actions.loadPoliceReportConfig();
    actions.getPoliceReports();
    actions.getAlerts();
  }, [state.dataSource.page, state.filters, appState.selectedHotel, state.date]);


  useEffect(() => {
    
    if (!state?.platform) {
      actions.getPlatform();
    }
    updateState({ lang: getSelectedLanguage().id });
  
  }, [appState.currentLanguage, state.platform, state.dataSource]);
  


  const actions = {
    getAlerts: () => {
      API.getActiveDashboardAlerts()
        .then(res => {
          if (res && res.length > 0) {
            const alerta = res[0];
            const lang = getSelectedLanguage();
            updateState({ isLoading: false, alertMessage: alerta || null, lang: lang.id });
          }
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      let InterfaceType = "PMS"
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          const format = 'YYYY-MM-DD';
          const file = res?.fileFormat || '';
          const extension = file ? file.substring(file.lastIndexOf('.') + 1) : '';
          const filePrefix = file.substring(0, file.indexOf(format));
          const fileWithoutExtension = file.replace(extension, '');
          const fileSufix = fileWithoutExtension.substring(
            fileWithoutExtension.indexOf(format) + format.length,
            fileWithoutExtension.length
          );
          // If the hotel does not have all this config asume it goes by PMS
          if(res?.ftpHost || res?.ftpPort || res?.ftpUser || res?.ftpPassword) {
            InterfaceType = "SFTP"
          }
          updateState({
            suscription: res?.suscription === 'active',
            host: res?.ftpHost,
            username: res?.ftpUser,
            password: res?.ftpPass,
            port: res?.ftpPort,
            fileFormat: '',
            filePrefix: filePrefix,
            fileSufix: fileSufix,
            folder: res?.ftpDirPath,
            extension: extension,
            InterfaceType: InterfaceType,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    getPoliceReports: () => {
      // const parsedReports = getParsedReports(JSON.parse(JSON.stringify(mock)));
      // updateState({ isLoading: false, dataSource: parsedReports });
      // return;

      // updateState({ isLoading: true });
      const hotel = getSelectedHotel();

      // Register action
      API.registerDashboardAction('police_report', 'get_police_reports', { hotelId: hotel.id || hotel.key, date: moment(state.date).format('YYYY-MM-DD'), page: state.dataSource.page });

      API.getPoliceReportList({
        hotelId: hotel.id || hotel.key,
        date: moment(state.date).format('YYYY-MM-DD'),
        page: state.dataSource.page,
        size: state.dataSource.size,
        status: state.filters?.status,
        reference: state.filters?.reference,
        guestName: state.filters?.guestName,
        batch: state.filters?.batch,
      })
        .then(res => {
          actions.setReportStatus(res);
          const parsedReports = getParsedReports(res);
          updateState({ isLoading: false, dataSource: parsedReports });

          
        })
        .catch(err => {

          updateState({ isLoading: false });
        });

    },

    validateFileExport: () => {
      if (!state.host) {
        actions.forcePoliceReportDownloadTrigger();
        return;
      }

      updateState({ isLoading: true });
      API.policeReportValidateFileExport({
        hotelId: getSelectedHotel()?.key,
        host: state.host,
        username: state.username,
        password: state.password,
        port: parseInt(state.port),
        folder: state.folder,
        platform: 'SES',
        file:
          state.filePrefix +
          moment(state.date).format('YYYY-MM-DD') +
          state.fileSufix +
          state.extension,
      })
        .then(res => {
          if (res.length > 0) {
            const errorItems = res.filter(item => item.error === 'error');
            if (errorItems.length > 0) {
              const sortedValidations = [...errorItems];
              updateState({
                isLoading: false,
                ftpErrors: sortedValidations,
                ftpResult: res && res.length > 0 ? res : [],
                isValidation: true,
              });
              return;
            }
          }
          actions.forcePoliceReportDownloadTrigger();
          updateState({ isLoading: false, ftpErrors: [], ftpResult: [], isValidation: true });
          return;
        })
        .catch(err => {
          console.warn(err);
          updateState({
            isLoading: false,
            ftpErrors: [],
            ftpResult: [],
            isValidation: true,
          });
          notification.error({
            message: translate('policeReport.fileNotFound'),
          });
        });
    },

    requestGuestlinkFunctionality: () => {
      updateState({ isLoading: true });
      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription: 'police_report',
        functionality: 'Police Report Module',
      })
        .then(_ => {
          actions.sendRequestPoliceFunctionalityEmail();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestPoliceFunctionalityEmail: () => {
      updateState({ isLoading: true });
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality: 'Police Report Module',
      })
        .then(_ => {
          notification.success({
            message: translate('policeReport.missingSuscription.requestSent'),
          });
          updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    forcePoliceReportDownloadTrigger: async () => {
      // If we have reports in this day, cant force the download
      if (state.dataSource?.totalItems > 0) {
        notification.warning({
          message: translate('policeReport.forceDownloadTriggerWarning'),
        });
        return;
      }

      const selectedHotel = getSelectedHotel();
      const hotelId = selectedHotel?.id ?? selectedHotel?.key;
      const date = moment(state.date).format('YYYY-MM-DD');
      updateState({ isLoadingForcePoliceReport: true });
      try {

        // Register action
        API.registerDashboardAction('police_report', 'force_download_trigger', { hotelId, date });

        await API.forcePoliceReportDownloadTrigger({ hotelId, date });
        notification.success({
          message: translate('policeReport.forceDownloadTriggerInfo'),
        });
        updateState({ isForcedPoliceReport: true });
      } catch (error) {
        notification.error({
          message: translate('policeReport.forceDownloadTriggerError'),
        });
      }
      updateState({ isLoadingForcePoliceReport: false });
    },

    getColorForStatus: status => {
      switch (status) {
        case 'ok':
          return Colors.testOk;
        case 'error':
          return Colors.red;
        case 'pending':
          return Colors.yellowPill;
        default:
          return Colors.lightGray;
      }
    },

    onDateChange: (_date, dateString) => {
      updateState({ datesRange: dateString });
    },

    onDateSet: date => {
      updateState({ date, isForcedPoliceReport: false });
    },

    onRowClicked: data => {
      const platform = state.platform;
      history.push({
        pathname: `/police-report/${data.id}`,
        pathname: `${platform.toLowerCase()}/police-report/${data.id}`,
        state: { report: data },
      });
      setPoliceReportFilters(state);

    },


    onChange: filters => {
      var newPage,
        newPageSize = -1;
      var searchFilters;

      if (state.dataSource?.page !== filters?.pagination.page) {
        newPage = filters?.pagination.page;
      }
      if (state.dataSource?.size !== filters?.pagination.pageSize) {
        newPageSize = filters?.pagination.pageSize;
      }

      if (filters?.where) {
        const reference = filters?.where['reference'];
        const guestName = filters?.where['surname'] || filters?.where['name'];
        const status = filters?.where['status'];
        const batch = filters?.where['batch'];

        searchFilters = { status, reference, guestName, batch };
      }

      updateState({
        dataSource: {
          ...state.dataSource,
          page: newPage >= 0 ? newPage : state.dataSource?.page,
          size: newPageSize >= 0 ? newPageSize : state.dataSource?.size,
          items: [],
        },
        filters: searchFilters,
      });
    },

    parseError: (error, object) => {
      if (error === undefined && !object.batch) {
        return translate('policeReport.erronInTheReport');
      } else if (error === undefined && object.batch) {
        return translate('policeReport.errorOnTargetPlatform');
      }

      try {
        let newString = error;
        newString = newString.replace(
          'Falta el campo requerido:',
          translate('policeReport.detailFields.requiredFields')
        );
        newString = newString.replace(
          'Error de validación del campo:',
          translate('policeReport.detailFields.fieldValidation')
        );

        const keysToReplace = Object.values(FIELDS);

        keysToReplace.forEach(key => {
          const regex = new RegExp(`\\b${key}\\b`, 'g');
          const translation = translate(`policeReport.detailFields.${key}`);
          newString = newString.replace(regex, translation);
        });

        return newString;
      } catch (errorMessage) {
        console.log('Error parsing message');
        return error;
      }
    },

    downloadPoliceReports: () => {
      updateState({ isLoading: true });
      API.downloadPoliceReports()
        .then(res => {
          saveAs(
            new Blob([res], {
              type: 'application/xml',
            }),
            'police_report_export.xml'
          );
          notification.success({
            message: translate('policeReport.downloadSuccess'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          notification.error({
            message: translate('policeReport.downloadError'),
          });
          updateState({ isLoading: false });
        });
    },
    getPlatform: () => {
      const hotelId = getSelectedHotel()?.key;
    
      API.getPoliceConfig({ hotelId })
        .then(res => {
         updateState({platform: res.platform});
        })

    },
    sendGetFiles: () => {
      updateState({ isLoading: true });
    
      const date = moment(state.date).format('YYYY-MM-DD');
      const hotelId = getSelectedHotel()?.key;
    
      API.getDownloadFiles({ hotelId: hotelId, date: date })
        .then(res => {
    
          if (res && res.file && res.file.data) {
            const fileBuffer = res.file.data; 
    
            const byteArray = new Uint8Array(fileBuffer); 
            const blob = new Blob([byteArray], { type: 'application/zip' });
    
            saveAs(blob, res.fileName);
            

          } else{
            notification.error({
              message: translate('policeReport.downloadError'),
            });
          }
        })
        .catch(err => {
          notification.error({
            message: translate('policeReport.downloadError'),
          });
          updateState({ isLoading: false });
        });
    
      updateState({ isLoading: false });
    },

    sendAllErrors: () => {
      updateState({ isLoading: true });

      const body = {
        date: moment(state.date).format('YYYY-MM-DD'),
        hotelId: getSelectedHotel()?.key,
      }
      API.policeReportSendAllErrors(body)
      .then(res => {
        setTimeout(() => {
          notification.success({
            message: translate('policeReport.sendAllErrorsSuccess'),
          });
  
          // Reload bookings to show results
          updateState({ dataSource: { isLoading: false, ...state.dataSource, items: [] } });
          actions.getPoliceReports();
        }, 5000); // 5 second delay to show results.
      })
      .catch(err => {
        updateState({ isLoading: false });
        notification.error({
          message: translate('policeReport.sendAllErrorsError'),
        });
      })
    },
    onDeleteAllDayClicked: ()=>{
      actions.setShowDeleteAllWarning(true);
    },
    setShowDeleteAllWarning: show => {
      updateState({ showAllDayDeleteWarning: show });
    },
    sendDeleteAll: () => {
      updateState({ isLoading: true });
    
      const data = {
        allDay: true,
        date: moment(state.date).format('YYYY-MM-DD'),
        hotelId: getSelectedHotel().key,
      };
    
      API.policeReportSendDelete(data)
      
        .then(res => {
         
          setTimeout(() => {
            notification.success({
              message: translate('policeReport.delete.success'),
            });
    
            // Reload bookings to show results
            updateState({ dataSource: { isLoading: false, ...state.dataSource, items: [], showAllDayDeleteWarning: false } });
            actions.getPoliceReports();
          }, 1000); // 5 second delay to show results.
    
          updateState({
            isLoading: false,
            showAllDayDeleteWarning: false,
          });
    
        })
        .catch(err => {
          notification.error({
            message: translate('policeReport.delete.error'),
          });
          updateState({ isLoading: false, showGuestDeshowAllDayDeleteWarningleteWarning: false });
        });
    },


    setReportStatus: (response) => {
      const items = response?.items;
      const reportStatus = response?.reportStatus;
      const sendTimestamp = response?.reportStatus?.timestamp;
      const fileSearchError = response?.reportStatus?.ErrorMessage;
      let status = "notSent"; // Default value

      // If no status on report but there ara bookings set Ok
      if (!reportStatus?.length && items?.length > 0) {
        status = "ok";
      }

      if (reportStatus?.status) {
        status = reportStatus.status;
      }

      updateState({statusSendReport: status, sendTimestamp: sendTimestamp, fileSearchError: fileSearchError});
    },


  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}

function getDefaultDateRange() {
  return [moment().subtract(1, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')];
}

const mock = {
  totalItems: 1,
  totalPages: 1,
  pageSize: 10,
  currentPage: 1,
  items: [
    {
      id: 'd85e6ac7-7cb4-47b9-9b8e-6a6212d1f251',
      report: {
        status: 'error',
        message: 'email required',
      },
      guest: {
        holder: true,
        name: 'Marcos',
        surname: 'Rubio',
        secondSurname: 'Torres',
        contact: {
          type: 'email',
          value: 'teste-addres.com',
        },
        documentType: 'NIF',
        documentNumber: '82738273G',
        supportDocument: 'B34827',
        customFields: {
          country: 'ESP',
          municipalityCode: '07014',
          zipCode: '07570',
          address: 'Avda. Calaberas 23',
          relationship: 'ABU',
        },
      },
      payment: {
        type: 'TARJT',
      },
      contract: {
        ref: '32VA6AF7S6',
        contractDate: '2023-04-25',
        entrance: '2023-04-25',
        departure: '2023-04-28',
        pax: 2,
      },
    },
    {
      id: '37bf1943-9f4c-4dbd-98ea-8db121695c6a',
      report: {
        status: 'success',
        message: 'booking confirmed',
      },
      guest: {
        holder: true,
        name: 'Sophia',
        surname: 'Martinez',
        secondSurname: 'Lopez',
        contact: {
          type: 'email',
          value: 'sophia@email.com',
        },
        documentType: "Driver's License",
        documentNumber: 'DL4567890',
        supportDocument: 'A9876543',
        customFields: {
          country: 'CAN',
          municipalityCode: 'M5V 2V4',
          zipCode: 'M1B 3C2',
          address: '456 Maple Avenue',
          relationship: 'FAMILY',
        },
      },
      payment: {
        type: 'PAYPAL',
      },
      contract: {
        ref: '9R6T4P1S2L',
        contractDate: '2023-06-10',
        entrance: '2023-06-10',
        departure: '2023-06-15',
        pax: 1,
      },
    },
    {
      id: 'f60f5a1d-0b05-4ebc-8c38-6f5ae8f09d13',
      report: {
        status: 'success',
        message: 'missing phone number',
      },
      guest: {
        holder: true,
        name: 'Emily',
        surname: 'Johnson',
        secondSurname: 'Smith',
        contact: {
          type: 'phone',
          value: '+1 123-456-7890',
        },
        documentType: 'Passport',
        documentNumber: 'AB1234567',
        supportDocument: 'D98765',
        customFields: {
          country: 'USA',
          municipalityCode: '90210',
          zipCode: '10001',
          address: '123 Elm Street',
          relationship: 'FRIEND',
        },
      },
      payment: {
        type: 'CREDIT',
      },
      contract: {
        ref: '5G8H9K2P3Q',
        contractDate: '2023-05-15',
        entrance: '2023-05-15',
        departure: '2023-05-20',
        pax: 3,
      },
    },
    {
      id: '8a2f62a2-9dcd-4be2-b69e-9f135a4ea54d',
      report: {
        status: 'error',
        message: 'invalid credit card',
      },
      guest: {
        holder: true,
        name: 'Michael',
        surname: 'Brown',
        secondSurname: 'Miller',
        contact: {
          type: 'phone',
          value: '+44 20 1234 5678',
        },
        documentType: 'ID Card',
        documentNumber: 'ID7890123',
        supportDocument: 'S6543210',
        customFields: {
          country: 'GBR',
          municipalityCode: 'SW1A 1AA',
          zipCode: 'W1B 3HH',
          address: '789 Park Lane',
          relationship: 'COLLEAGUE',
        },
      },
      payment: {
        type: 'CREDITCARD',
      },
      contract: {
        ref: '1C3R5E7D9I',
        contractDate: '2023-07-20',
        entrance: '2023-07-20',
        departure: '2023-07-25',
        pax: 1,
      },
    },
  ],
};
