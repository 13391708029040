import axios from 'axios';
import apiConfig from '../api.config';

export default function policeReportValidateFileExport(data) {
  const {
    host,
    URIs: { policeReportValidateFileExport },
  } = apiConfig;

  const uri = `${host}${policeReportValidateFileExport}`;

  return axios
    .post(uri, data)
    .then(res => {
      return res.data;
    });
}
