export default {
  name: 'Nombre',
  surname: 'Apellido',
  second_surname: 'Segundo Apellido',
  document_country: 'País del documento',
  document_number: 'Número de documento',
  sex: 'Sexo (M/F)',
  nationality: 'Nacionalidad',
  nationalityGuest: 'Nacionalidad',
  birth_date: 'Fecha de nacimiento',
  expedition_date: 'Fecha de expedición',
  expiration_date: 'Fecha de caducidad',
  document_type: 'Tipo de Documento',
  male: 'Masculino',
  female: 'Femenino',
  email: 'Correo electrónico',
  'data-privacy-policy-label': 'He leído y acepto la política de privacidad de datos.',
  'data-commercial-use-label': 'Deseo recibir información comercial.',
  'loyalty-program-label': 'Quiero suscribirme al programa de fidelización.',
  phone: 'Teléfono',
  postalCode: 'Código postal de residencia',
  address: 'Dirección de residencia',
  city: 'Ciudad de residencia',
  province: 'Provincia de residencia',
  state: 'Estado de residencia',
  country: 'País de residencia',
  birthCountry: 'País de nacimiento',
  birthProvince: 'Provincia de nacimiento',
  birthCity: 'Ciudad de nacimiento',
  allergy: 'Alergias',
  noCommonAllergy: 'Alergias no comunes',
  maritalStatus: 'Estado civil',
  occupation: 'Ocupación',
  mobilityProblems: 'Problemas de mobilidad',
  plateNumber: 'Matrícula del vehículo',
  firstTime: 'Primera vez hospedado',
  paymentType: 'Tipo de pago',
  environmentalTax: 'Tasa medioambiental',
  hotelInsurance: 'Aceptación del cargo por seguro hotelero',
  arrivalTime: 'Hora de llegada',
  arrivalFlight: 'Número del vuelo de llegada',
  arrivalFlightTime: 'Hora de llegada del vuelo',
  departureTime: 'Hora de salida',
  departureFlight: 'Número del vuelo de salida',
  departureFlightTime: 'Hora de salida del vuelo',
  howMeet: 'Cómo conoció a la propiedad',
  customerNotes: 'Comentarios del huésped',
  uploadDocument1: 'Upload document 1',
  uploadDocument2: 'Upload document 2',
  document_support_number: 'Número de soporte del documento',
  landline_phone: 'Teléfono fijo',
  family_relationship: 'Relacion de parentesco',
  payment_type: 'Tipo de pago',
  documentIssuanceCountry: 'País de emisión del documento',
  documentIssuanceProvince: 'Provincia de emisión del documento',
  documentIssuanceCity: 'Ciudad de emisión del documento',
  travel_reason: 'Motivo de la estancia',
};
