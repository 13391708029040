import React, { createContext, useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

// Crear el contexto
const GoogleAuthContext = createContext();

// Proveedor del contexto
export const GoogleAuthProvider = ({ children }) => {
  // Inicializa useGoogleLogin en el cuerpo del componente
  const googleLogin = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/gmail.send',
    flow: 'auth-code',
    onSuccess: async (response) => {
      try {
        const { code } = response;
        if (loginResolver) {
          loginResolver(code); // Resuelve la promesa con el código
        }
      } catch (error) {
        console.error('Error durante el procesamiento de onSuccess:', error);
        if (loginRejecter) {
          loginRejecter(error); // Rechaza la promesa en caso de error
        }
      } finally {
        resetLoginHandlers(); // Limpia los handlers
      }
    },
    onError: (error) => {
      console.error('Error en login:', error);
      if (loginRejecter) {
        loginRejecter(error); // Rechaza la promesa en caso de error
      }
      resetLoginHandlers(); // Limpia los handlers
    },
  });

  // Variables para manejar la promesa
  let loginResolver = null;
  let loginRejecter = null;

  // Resetea los handlers de la promesa
  const resetLoginHandlers = () => {
    loginResolver = null;
    loginRejecter = null;
  };

  // Función login que devuelve una promesa
  const login = () => {
    return new Promise((resolve, reject) => {
      loginResolver = resolve;
      loginRejecter = reject;
      googleLogin(); // Llama a la función de inicio de sesión
    });
  };

  return (
    <GoogleAuthContext.Provider value={{ login }}>
      {children}
    </GoogleAuthContext.Provider>
  );
};

// Hook para acceder al contexto
export const useGoogleAuth = () => useContext(GoogleAuthContext);