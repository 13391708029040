import axios from 'axios';
import apiConfig from '../api.config';

export default function downloadPoliceReport() {
  const {
    host,
    URIs: { downloadPoliceReports },
  } = apiConfig;

  const uri = `${host}${downloadPoliceReports}`;

  return axios
    .get(uri, {
      headers: {
        'Content-Type': 'application/xml',
      },
      responseType: 'arraybuffer',
    })
    .then(res => {
      return res.data;
    });
}
