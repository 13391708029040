import React, { useContext } from 'react';
import { GuestlinkConfigContext } from '../GuestlinkConfig.context.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import MailTemplateInput from 'Components/Dashboard/Guestlink/MailTemplateDetail/MailTemplateInput/MailTemplateInput.jsx';
import Loading from 'Components/common/Loader/Loading.jsx';
import { AppContext } from 'App.context.js';
import Modal from 'antd/lib/modal/Modal';
import TestEmailModal from '../../MailTemplateDetail/TestEmailModal/TestEmailModal.jsx';
import SaveButton from 'Components/common/SaveButton/SaveButton.jsx';
import styles from '../SmtpConfig/SmtpConfig.styles.js';
import useGmailConfigHook from './GmailConfig.hook.js';

export default function GmailConfig() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state: GuestlinkConfigState, actions: GuestlinkConfigActions } =
    useContext(GuestlinkConfigContext);
  const { state, actions } = useGmailConfigHook();

  return (
    <div>
      {!state.accessToken && !state.refreshToken ? (
        <div style={styles.buttonContainer}>
          <AppButton text={translate('guestlink.gmailConnect')} action={actions.handleLogin} />
        </div>
      ) : (
        <>
          <div style={styles.rowContainer}>
            <MailTemplateInput
              value={state.senderEmail}
              placeholder={translate('guestlink.defaultSenderPlaceholder')}
              name={translate('guestlink.defaultSender')}
              onChange={actions.handleSenderEmailChange}
            />
          </div>

          <div style={styles.buttonContainer}>
            {GuestlinkConfigState.isLoading && <Loading size={'small'} />}
            <SaveButton
              text={translate('guestlink.save')}
              action={applyToAllHotels =>
                GuestlinkConfigActions.onSaveSmtpAuthConfig(applyToAllHotels)
              }
              disabled={GuestlinkConfigState.isLoading}
            />
            <div style={styles.extraButtonsContainer}>
              <AppButton
                text={translate('guestlink.sendTestEmail')}
                action={() => GuestlinkConfigActions.setShowTestEmailModal(true)}
                secondary
                disabled={GuestlinkConfigState.isLoading}
              />
            </div>
          </div>

          <Modal
            open={GuestlinkConfigState.showSendTestEmailModal}
            onCancel={() => GuestlinkConfigActions.setShowTestEmailModal(false)}
            okText={translate('mailTemplates.sendButton')}
            confirmLoading={GuestlinkConfigState.isSendingTestEmail}
            onOk={() => GuestlinkConfigActions.onSendTestEmail()}
          >
            <TestEmailModal
              setShowModal={GuestlinkConfigActions.setShowTestEmailModal}
              setMail={GuestlinkConfigActions.setTestEmailAddress}
            />
          </Modal>
        </>
      )}
    </div>
  );
}
