import React, { useContext } from 'react';
import { Table, Input } from 'antd';
import useGuestHook from './hooks/Guest.hook';
import guestStyles from './Guest.styles';
import { useSupportedFields } from '../../../CheckIn/GuestTable/useSupportedFields';
import UnavailableBadge from '../../../CheckIn/GuestTable/UnavailableInPms/UnavailableInPms.jsx';
import usePMSUDFConfigHook from '../../PMSUDFConfig/PMSUDFConfig.hook';
import { AppContext } from 'App.context';

export default function DynamicFormTable({ mock, setMock, translate }) {
  const variableName = translate('validation.guest.response.variableName');
  const civitfunData = translate('validation.guest.response.civitfunData');
  const variablePMSName = translate('validation.guest.response.variablePMSName');

  const { appState } = useContext(AppContext);
  const { snakeToCamel, snakeToCapitalizedWithSpace } = useGuestHook();
  const { supportedFields, pmsModuleName } = useSupportedFields();
  const { state: pmsUdfState } = usePMSUDFConfigHook();

  const handleInputChange = (e, key) => {
    const newValue = e.target.value;

    setMock(prevState => ({
      fields: prevState.fields.map(field =>
        field.key === key ? { ...field, value: newValue } : field
      ),
    }));
  };

  const columns = [
    {
      title: variableName,
      dataIndex: 'key',
      key: 'key',
      render: text => (
        <b style={guestStyles.keyColumn}>{snakeToCapitalizedWithSpace(snakeToCamel(text))}</b>
      ),
    },
    {
      title: variablePMSName,
      dataIndex: 'key',
      key: 'key',
      render: text => {
        const fieldName = snakeToCamel(text);

        if (supportedFields.length == 0) return;

        const normalizedField = fieldName.trim().toLowerCase();

        const supportedFieldsMap = new Map(
          supportedFields.map(({ civitfun, pms }) => [civitfun.trim().toLowerCase(), pms])
        );

        let pmsName = '';
        if (normalizedField === 'nationalityguest' && supportedFieldsMap.has('nationality')) {
          pmsName = supportedFieldsMap.get('nationality');
        } else {
          pmsName = supportedFieldsMap.get(normalizedField);
        }

        // check UDF values
        if (pmsUdfState) {
          pmsUdfState.hotelUdfs.forEach(udf => {
            if (snakeToCamel(udf.validate) == fieldName) {
              if (pmsName) pmsName += ' + ' + udf.name;
              else pmsName = udf.name;
            }
          });
        }

        return <div>{pmsName || <UnavailableBadge pmsModuleName={pmsModuleName} fieldName={fieldName} />}</div>;
      },
    },
    {
      title: civitfunData,
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => (
        <div style={guestStyles.valueColumnForm}>
          <Input
            value={text}
            onChange={e => handleInputChange(e, record.key)}
            style={guestStyles.input}
          />
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <Table
      columns={columns}
      dataSource={mock.fields}
      rowKey="key"
      pagination={false}
      style={guestStyles.table}
    />
  );
}
