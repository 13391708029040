import { useContext, useEffect, useState } from 'react';
import { GuestlinkConfigContext } from '../GuestlinkConfig.context.js';
import { useGoogleAuth } from 'context/GoogleAuthContext';

export default function useGmailConfigHook() {
  const { login } = useGoogleAuth();
  const { state: GuestlinkConfigState, actions: GuestlinkConfigActions } =
    useContext(GuestlinkConfigContext);

  const [state, setState] = useState({
    accessToken: GuestlinkConfigState.accessToken,
    refreshToken: GuestlinkConfigState.refreshToken,
    senderEmail: GuestlinkConfigState.default_sender,
  });

  useEffect(() => {
    updateState({
      accessToken: GuestlinkConfigState.accessToken ?? null,
      refreshToken: GuestlinkConfigState.refreshToken ?? null,
      senderEmail: GuestlinkConfigState.default_sender ?? null,
    });
  }, [
    GuestlinkConfigState.integrationType,
    GuestlinkConfigState.accessToken,
    GuestlinkConfigState.refreshToken,
    GuestlinkConfigState.default_sender,
  ]);

  const actions = {
    handleSenderEmailChange: value => {
      updateState({ senderEmail: value });
      GuestlinkConfigActions.setField('default_sender', value);
    },

    handleLogin: async () => {
      try {
        const code = await login();
        if (code) {
          await GuestlinkConfigActions.googleAuthApi(code);
        } else {
          console.error('No authorization code received.');
        }
      } catch (error) {
        console.error('Login error:', error);
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return {
    state,
    actions,
  };
}
