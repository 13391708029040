import { AppContext } from 'App.context';
import { useState, useEffect, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { Modal, notification } from 'antd';
import { validateEmail } from 'utils/TextUtils';

export default function useUsersHook() {
  const { confirm } = Modal;
  const [state, setState] = useState({
    users: null,
    isLoading: false,
    name: null,
    email: null,
    hotel: null,
    hotels: [],
    role: null,
    isDeleteModalVisible: false,
    userToDelete: null,
    isEditModalVisible: false,
    userToEdit: null,
    isOwnerRoleModalVisible: false,
  });
  const { API } = useContext(ApiContext);
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  useEffect(() => {
    actions.loadUsers();
    actions.loadHotels();
  }, []);

  const actions = {
    loadUsers: () => {
      updateState({ isLoading: true });
      API.userListRequest({ ownerId: appState.ownerId })
        .then(res => {
          updateState({ users: res, isLoading: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    loadHotels: () => {
      updateState({ isLoading: true });
      API.allHotelsRequest()
        .then(res => {
          updateState({ hotels: res, isLoading: false });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onSelectNewHotel: (value) => {
      if (value === 'all') {
        updateState({ hotel: [ 'all' ] });
        return;
      }

      const hotel = state.hotel || [];

      if (hotel.includes('all')) {
        updateState({ hotel: [ value ] });
      } else {
        hotel.push(value);
        updateState({ hotel: hotel });
      }
    },

    onDeselectNewHotel: (value) => {
      const hotels = state.hotel || [];

      const index = hotels.indexOf(value);

      if (index > -1) {
        hotels.splice(index, 1);
        updateState({ hotel: hotels });
      }
    },

    onClearNewHotel: () => {
      updateState({ hotel: [] });
    },

    onSelectEditHotel: (value) => {
      if (value === 'all') {
        actions.setUserToEditField('hotel', [ 'all' ]);
        return;
      }

      const hotels = state.userToEdit?.hotel ? state.userToEdit.hotel : [];
        
      if (hotels.includes('all')) {
        actions.setUserToEditField('hotel', [ value ]);
      } else {
        hotels.push(value);
        actions.setUserToEditField('hotel', hotels);
      }
    },
    
    onDeselectEditHotel: (value) => {
      const hotels = state.userToEdit?.hotel ? state.userToEdit.hotel : [];

      const index = hotels.indexOf(value);

      if (index > -1) {
        hotels.splice(index, 1);
        actions.setUserToEditField('hotel', hotels);
      }
    },

    onClearEditHotel: () => {
      actions.setUserToEditField('hotel', []);
    },

    validateFieldsAndSend: () => {
      if (!state.name || !state.email || !state.role || !state.hotel) {
        notification.error({ message: translate('user.emptyFields') });
        return;
      }

      if (!validateEmail(state.email)) {
        notification.error({
          message: translate('recover.invalidEmail'),
        });
        return;
      }
      
      actions.showSendInvitationWarning();
    },

    validateEditFields: () => {
      const userToEdit = state.userToEdit;

      if (!userToEdit.name || !userToEdit.email || !userToEdit.role || !userToEdit.hotel) {
        notification.error({ message: translate('user.emptyFields') });
        return false;
      }

      if (!validateEmail(userToEdit.email)) {
        notification.error({
          message: translate('recover.invalidEmail'),
        });
        return false;
      }

      return true;
    },

    showSendInvitationWarning: () => {
      confirm({
        title: translate('user.sendInvitationWarning').replace('#', state?.email),
        onOk() {
          actions.sendInvitation();
        },
        onCancel() {
          return;
        },
      });
    },

    sendInvitation: () => {
      updateState({ isLoading: true });
      API.sendUserInvitationRequest({
        name: state.name,
        email: state.email,
        ownerId: appState.ownerId,
        hotel: state.hotel,
        role: state.role,
      })
      .then(res => {
        updateState({ isLoading: false });
        notification.success({ message: translate('user.sendInviteSuccess') });
        actions.loadUsers();
      })
      .catch(err => {
          updateState({ isLoading: false });
          notification.error({
            message: translate('user.sendInviteError')
          });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    setUserToEditField: (fieldKey, value) => {
      const userToEdit = { ...state.userToEdit };
      userToEdit[fieldKey] = value;
      updateState({ userToEdit });
    },

    openDeleteUserModal: (userId) => {
      updateState({ isDeleteModalVisible: true, userToDelete: userId });
    },

    closeDeleteUserModal: () => {
      updateState({ isDeleteModalVisible: false, userToDelete: null });
    },

    openEditUserModal: (row) => {
      const userClone = JSON.parse(JSON.stringify(row));
      updateState({ isEditModalVisible: true, userToEdit: userClone, originalUserData: row });
    },

    closeEditUserModal: () => {
      updateState({ isEditModalVisible: false, userToEdit: null, originalUserData: null });
    },

    delete: (userId) => {
      updateState({ isLoading: true });
      API.deleteUserRequest({ userId: userId })
        .then(() => {
          notification.success({ message: translate('team.usersDetail.deleteSuccess') });
          actions.loadUsers();
        })
        .catch(() => {
          notification.error({
            message: translate('team.usersDetail.deleteError'),
          });
        })
        .finally(() => {
          updateState({ isLoading: false, isDeleteModalVisible: false, userToDelete: null });
        });
    },

    update: () => {
      if (state.userToEdit.role === "owner" && state.originalUserData?.role !== "owner") {
        actions.openOwnerRoleModal();
      } else {
        updateUser(state.userToEdit);
      }
    },

    openOwnerRoleModal: () => {
      updateState({ isOwnerRoleModalVisible: true });
    },

    closeOwnerRoleModal: () => {
      updateState({ isOwnerRoleModalVisible: false });
    },

    confirmOwnerRoleChange: () => {
      updateUser(state.userToEdit);
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  const updateUser = (user) => {
    if (!actions.validateEditFields()) return;

    updateState({ isLoading: true });

    API.updateUserRequest({
      id: user.id,
      ownerId: appState.ownerId,
      name: user.name,
      email: user.email,
      hotel: user.hotel,
      role: user.role,
    })
      .then(() => {
        notification.success({ message: translate("team.usersDetail.editSuccess") });
        actions.loadUsers();
      })
      .catch(() => {
        notification.error({ message: translate("team.usersDetail.editError") });
      })
      .finally(() => {
        updateState({
          isLoading: false,
          isEditModalVisible: false,
          userToEdit: null,
          isOwnerRoleModalVisible: false,
        });
      });
  };

  return { state, actions };
}
