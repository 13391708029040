import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { capitalize } from 'utils/TextUtils.js';

const getColumns = (translate, actions, state) => {
  return [
    {
      title: translate('policeReport.batchCol'),
      dataIndex: 'batch',
      key: 'batch',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.batch ? [state.filters?.batch] : [],
    },
    {
      title: translate('policeReport.dateCol'),
      dataIndex: 'entrance',
      key: 'entrance',
      sorter: false,
      render: (date, item) => {
        return <div>{moment(date).format('DD-MM-YYYY')}</div>;
      },
    },
    {
      title: translate('policeReport.contractCol'),
      dataIndex: 'reference',
      key: 'reference',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.reference ? [state.filters?.reference] : [],
    },

    {
      title: translate('policeReport.guestNameCol'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.guestName ? [state.filters?.guestName] : [],
      render: value => {
        return <div>{capitalize(value)}</div>;
      },
    },
    {
      title: translate('policeReport.guestSurnameCol'),
      dataIndex: 'surname',
      key: 'surname',
      sorter: false,
      searchable: true,
      render: value => {
        return <div>{capitalize(value)}</div>;
      },
    },
    {
      title: translate('policeReport.actionCol'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (_, object) => {
        return (
          <Button
            type="secondary"
            icon={<EyeOutlined />}
            onClick={() => actions.onRowClicked(object)}
          />
        );
      },
    },
  ];
};

export default getColumns;
