export default {
  name: 'Name',
  surname: 'Last name',
  second_surname: 'Second last name',
  document_country: 'Document country',
  document_number: 'Document number',
  sex: 'Gender (M/F)',
  nationality: 'Nationality',
  nationalityGuest: 'Nationality',
  birth_date: 'Date of birth',
  expedition_date: 'Date of issue',
  expiration_date: 'Valid until',
  document_type: 'Document type',
  male: 'Male',
  female: 'Female',
  email: 'E-mail',
  'data-privacy-policy-label': 'I have read and accept the data privacy policy.',
  'data-commercial-use-label': 'I want to receive commercial information.',
  'loyalty-program-label': 'I want to subscribe loyalty program.',
  phone: 'Phone',
  postalCode: 'Residence postal code',
  address: 'Residence address',
  city: 'Residence city',
  province: 'Residence province',
  state: 'Residence state',
  country: 'Residence country',
  birthCountry: 'Birth country',
  birthProvince: 'Birth province',
  birthCity: 'Birth city',
  allergy: 'Allergies',
  noCommonAllergy: 'No common allergies',
  maritalStatus: 'Marital status',
  occupation: 'Occupation',
  mobilityProblems: 'Mobility problems',
  plateNumber: 'Plate number',
  firstTime: 'First time staying',
  paymentType: 'Payment type',
  environmentalTax: 'Environmental tax',
  hotelInsurance: 'Acceptance of the charge for hotel insurance',
  arrivalTime: 'Arrival time',
  arrivalFlight: 'Arrival flight number',
  arrivalFlightTime: 'Arrival flight time',
  departureTime: 'Departure time',
  departureFlight: 'Departure flight number',
  departureFlightTime: 'Departure flight time',
  howMeet: 'How did you find out about the property',
  customerNotes: 'Customer notes',
  uploadDocument1: 'Upload document 1',
  uploadDocument2: 'Upload document 2',
  document_support_number: 'Document support number',
  landline_phone: 'Landline phone',
  family_relationship: 'Family relationship',
  payment_type: 'Payment type',
  documentIssuanceCountry: 'Country of issue of the document',
  documentIssuanceProvince: 'Province of issue of the document',
  documentIssuanceCity: 'City of issue of the document',
  travel_reason: 'Purpose of stay',
};
