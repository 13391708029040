import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function usePoliceReportSFTPConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);
  

  const [state, setState] = useState({
    isLoading: false,
    ftpHost: null,
    ftpUser: null,
    ftpPass: null,
    fileFormat: null,
    filePrefix: null,
    fileSufix: null,
    ftpDirPath: null,
    sesUser: null,
    sesPass: null,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    ftpFiles: 0,
    ftpErrors: 0,
    ftpResult: [],
    testFile: null,
    isValidation: false,
    isFileList: false,
    dateSelected: null,
  });

  useEffect(() => {
    actions.loadPoliceReportConfig();
  }, [appState.selectedHotel]);


  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          // This could be refactored!! Get the sufix and the prefix from the file
          const possibleFormats = ['YYYY-MM-DD', 'YYYYMMDD', 'DD-MM-YYYY', 'DDMMYYYY'];
          const file = res?.fileFormat || '';
          const extension = file ? file.substring(file.lastIndexOf('.') + 1) : '';
          const fileWithoutExtension = file.replace(extension, '');

          let detectedFormat = possibleFormats.find((fmt) => fileWithoutExtension.includes(fmt));

          if (!detectedFormat) {
            detectedFormat = 'YYYY-MM-DD';
          }
          const filePrefix = file.substring(0, file.indexOf(detectedFormat));
          const fileSufix = fileWithoutExtension.substring(
            fileWithoutExtension.indexOf(detectedFormat) + detectedFormat.length,
            fileWithoutExtension.length
          );

          




          updateState({
            isLoading: false,
            ftpHost: res?.ftpHost,
            ftpUser: res?.ftpUser,
            ftpPass: res?.ftpPass,
            ftpPort: res?.ftpPort,
            fileFormat: `${detectedFormat}`,
            filePrefix: filePrefix,
            fileSufix: fileSufix,
            sesUser: res?.sesUser,
            sesPass: res?.sesPass,
            ftpDirPath: res?.ftpDirPath,
            extension: extension,
            lessorCode: res?.lessorCode,
            establishmentCode: res?.establishmentCode,
            suscription: res?.suscription === 'active',
            dateSelected: res?.dateSelected ,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },


    onCheckConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSFTPConnection({
        hotelId: getSelectedHotel()?.key,
        host: state.ftpHost,
        username: state.ftpUser,
        password: state.ftpPass,
        port: parseInt(state.ftpPort),
        folder: state.ftpDirPath,
      })
      .then(res => {
        updateState({
          isLoading: false,
          ftpFiles: res && res.length > 0 ? res : [],
          isValidation: false,
          isFileList: true,
        });
        notification.success({
          message: translate('policeReport.checkConnectionSuccess'),
        });
      })
      .catch(err => {
        console.warn(err);
        updateState({
          isLoading: false,
          ftpFiles: [],
          ftpResult: [],
          isValidation: false,
          isFileList: true,
        });
        notification.error({
          message: translate('policeReport.checkConnectionError'),
        });
      });
    },

    onSaveFTPConfig: () => {
      updateState({ isLoading: true });

      if (state.extension !== 'txt' && state.extension !== 'xml') {
        actions.setField('extension', 'txt');
        state.extension = 'txt';
      }

      if (state.fileSufix.endsWith('.')) {
        state.fileSufix = state.fileSufix.replace(/\.+$/, '');
      }

      const possibleFormats = ['YYYY-MM-DD', 'YYYYMMDD', 'DD-MM-YYYY', 'DDMMYYYY'];
      if ( !possibleFormats.includes(state.fileFormat) ) {
        state.fileFormat = 'YYYY-MM-DD';
        actions.setField('fileFormat', 'YYYY-MM-DD');
      }

      API.updatePoliceSFTP({
        hotelId: getSelectedHotel()?.key,
        ftpConfig: {
          host: state.ftpHost,
          user: state.ftpUser,
          password: state.ftpPass,
          port: state.ftpPort,
          dateSelected: state.dateSelected,
        },
        fileFormat: state.extension
          ? state.fileFormat + '.' + state.extension
          : state.fileFormat + '.txt',
        filePrefix: state.filePrefix,
        fileSufix: state.fileSufix,
        ftpDirPath: state.ftpDirPath,
      })
        .then(_ => {
          API.policeReportCheckSFTPConnection({
            hotelId: getSelectedHotel()?.key,
            host: state.ftpHost,
            username: state.ftpUser,
            password: state.ftpPass,
            port: parseInt(state.ftpPort),
            folder: state.ftpDirPath,
          })
            .then(res => {
              updateState({
                isLoading: false,
                ftpFiles: res && res.length > 0 ? res : [],
                isValidation: false,
                isFileList: true,
              });
              notification.success({
                message: translate('policeReport.checkConnectionSuccess'),
              });
            })
            .catch(err => {
              console.warn(err);
              updateState({
                isLoading: false,
                ftpFiles: [],
                ftpResult: [],
                isValidation: false,
                isFileList: true,
              });
              notification.error({
                message: translate('policeReport.checkConnectionError'),
              });
            });
        })
        .catch(err => {
          // ERROR saving credentials
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.ftpError'),
          });
        });
    },

    validateFileExport: () => {
      updateState({ isLoading: true });
      API.policeReportValidateFileExport({
        hotelId: getSelectedHotel()?.key,
        host: state.ftpHost,
        username: state.ftpUser,
        password: state.ftpPass,
        port: parseInt(state.ftpPort),
        folder: state.ftpDirPath,
        platform: 'SES',
        file: state.testFile,
      })
        .then(res => {
          const fileContent = res.fileContent || '';
          const errors = res.errors || [];

          if (errors.length > 0) {
            const errorItems = errors.filter(item => item.error === 'error');
            const otherItems = errors.filter(item => item.error !== 'error');

            const sortedValidations = [...errorItems, ...otherItems];
            updateState({
              isLoading: false,
              ftpErrors: sortedValidations,
              ftpResult: errors && errors.length > 0 ? errors : [],
              isValidation: true,
              isFileList: false,
              fileContent: fileContent,
            });
          } else {
            updateState({ isLoading: false, ftpErrors: [], ftpResult: [], isValidation: true, isFileList: false, fileContent: fileContent });
          }
          notification.success({
            message: translate('policeReport.validateFileExportSuccess'),
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false, ftpErrors: [], ftpFiles: [], ftpResult: [], isValidation: true, isFileList: false });
          notification.error({
            message: translate('policeReport.validateFileExportError'),
          });
        });
    },

    setField: (fieldKey, value) => {
      if (fieldKey === 'extension' && value.includes('.')) {
        value = value.replace('.', '');
      }

      updateState({ [fieldKey]: value });

    },

    isAdmin: () => {
      return appState?.superUser ? true : false;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }


  return { state, actions };
}
