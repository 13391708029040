import Colors from 'constants/Colors';

export default {
  header: {
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    paddingTop: 16,
    flexDirection: 'row',
    gap: 16,
    marginLeft: 32,
  },
  headerMapping: {
    display: 'flex',
    gap: 16,
    marginLeft: 32,
    marginBottom: 16,
  },
  textMapping: {
    display: 'flex',
    gap: 8,
    color: Colors.darkText,
    alignItems: 'center',
  },
  textWithIcon: {
    display: 'flex',
    gap: 5,
  },
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.darkText,
    padding: 10,
    borderRadius: 14,
  },
  footer: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: 32,
    marginBottom: 32,
    alignItems: 'center',
  },
  container: {
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 32,
    borderRadius: 14,
    padding: 14,
    flex: 1,
    backgroundColor: Colors.white,
  },
  dynamicEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 550,
    width: '100%',
    backgroundColor: Colors.white,
  },
};
