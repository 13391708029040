import React, { useContext, useEffect, useState } from 'react';
import { Upload, Input } from 'antd'; // Importamos Input de Ant Design
import styles from './PoliceMOSSOS.styles.js';
import { AppContext } from 'App.context.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import usePoliceReportMOSSOSConfigHook from './PoliceMOSSOS.hook.js';
import Colors from 'constants/Colors';

export default function PoliceERTZ() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePoliceReportMOSSOSConfigHook();
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);


  useEffect(() => {
    if (!isConfigLoaded) {
      actions.loadPoliceReportConfig();
      setIsConfigLoaded(true);
    }
  }, [actions, isConfigLoaded]);

  const saveERTZConfig = () => {
    actions.onSaveERTZConfig();
  };

  return (
    <div>
      <div style={styles.credentialsContainer}>
        <div style={styles.inputContainer}>
          <b style={styles.header}>{translate('policeReport.establishmentCode')}</b>
          <Input
            bordered={false}  
            value={state.establishmentCode || ''}
            onChange={(e) => actions.setField('establishmentCode', e.target.value)}
            disabled={state.isLoading}
            style={{ backgroundColor: Colors.inputBg }}
          />
        </div>
      </div>
      <div style={styles.buttonContainer}>
        {/* <AppButton TODO
          text={translate('policeReport.checkConnection')}
          secondary
          action={() => actions.onCheckSESConnection()}
          disabled={state.isLoading}
        /> */}
        <AppButton
          text={translate('policeReport.save')}
          action={saveERTZConfig}
          disabled={state.isLoading}
        />
      </div>
    </div>
  );
}
