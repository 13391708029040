import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'App.context';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import ContentBox from 'Components/common/ContentBox/ContentBox';
import Loading from 'Components/common/Loader/Loading';
import styles from './DoorOpening.styles';
import useDoorOpeningHook from './DoorOpening.hook';
import InfoPill from 'Components/common/InfoPill/InfoPill';
import { Table } from 'antd';
import DoorOpeningSelector from './DoorOpeningSelector/DoorOpeningSelector';
import Colors from 'constants/Colors';
import { EditOutlined } from '@ant-design/icons';
import DoorOpeningForm from './DoorOpeningForm/DoorOpeningForm';
import RequestSuscription from 'Components/common/RequestSuscription/RequestSuscription.jsx';
import CustomButton from 'Components/common/CustomButton/CustomButton';

export default function DoorOpening() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useDoorOpeningHook();

  const columns = [
    {
      title: translate('state'),
      key: 'name',
      dataIndex: 'name',
      width: '15%',
      align: 'center',
      render: (_, row) => (
        <InfoPill
          color={actions.getColorStateFromPMS(row)}
          backgroundColor={actions.getBackgroundColorStateFromPMS(row)}
          text={actions.getTextStateFromPMS(row)}
        />
      ),
    },
    {
      title: translate('hotel'),
      dataIndex: 'name',
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.name}</div>;
      },
    },
    {
      title: translate('doorOpening.platform'),
      dataIndex: 'id',
      width: '30%',
      align: 'left',
      render: (_, row) => {
        return (
          <DoorOpeningSelector
            row={row}
            doorOpeningIntegrations={state.integrations}
            selectedDoorOpening={actions.getSelectedDoorOpeningForHotel(row.id)}
            setSelectedDoorOpening={actions.setSelectedDoorOpening}
            disabled={actions.isEnabled(row.id)}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <DefaultLayout singleBox title={translate('layout.doorOpening')} enableAllHotels={true}>
      <div style={{ paddingLeft: 32, paddingRight: 32 }}>
        {state.suscription ? (
          <ContentBox noPadding singleBox>
            {state.isLoading ? (
              <div style={styles.loadingContainer}>
                <Loading />
              </div>
            ) : (
              state.hotels &&
              state.selectedDoorOpening && (
                <Table
                  columns={columns}
                  dataSource={state.hotels}
                  pagination={state.hotels?.length > 10}
                  expandable={{
                    rowExpandable: record => actions.isRowExpandable(record.id),
                    expandedRowRender: record => {
                      return (
                        <DoorOpeningForm
                          rowData={record}
                          selectedDoorOpening={actions.getSelectedDoorOpeningForHotel(record.id)}
                          updateEnabledHotels={actions.updateEnabledHotels}
                          enabled={actions.isEnabled(record.id)}
                        />
                      );
                    },
                    expandedRowKeys: [state.expandedRow],
                    onExpand: (expanded, record) => {
                      actions.onExpanded(record.id, expanded);
                    },
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return (
                        <div>
                          {!state.oauthButton && ( 
                            <CustomButton
                              style={{
                                ...styles.button,
                                backgroundColor: Colors.pmsConnect,
                                width: '15vw',
                                color: Colors.white,
                              }}
                              onClick={() => actions.generateOauth()}
                              text={translate('setup')}
                            />
                          )}
                          {state.oauthButton && (
                            actions.isRowExpandable(record.id) ? (
                              expanded ? (
                                <ExpandIcon
                                  bg={Colors.blueIntense}
                                  onClick={onExpand}
                                  record={record}
                                  color={Colors.lightGreen}
                                />
                              ) : (
                                <ExpandIcon
                                  bg={Colors.blueBoxColor}
                                  onClick={onExpand}
                                  record={record}
                                  color={Colors.grayText}
                                />
                              )
                            ) : null
                          )}
                        </div>
                      );
                    },
                  }}
                />
              )
            )}
          </ContentBox>
        ) : (
          <RequestSuscription suscription='door_opening' />
        )}
      </div>
    </DefaultLayout>
  );
}

// Componente ExpandIcon
function ExpandIcon({ bg, onClick, record, color }) {
  return (
    <div
      style={{ ...styles.button, backgroundColor: bg }}
      onClick={event => onClick(record, event)}
    >
      <EditOutlined style={{ color }} />
    </div>
  );
}
