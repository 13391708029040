import React, { useContext, useState } from 'react';
import { Button, Modal, Table, Checkbox, Popover } from 'antd';
import { AppContext } from '../../../../App.context';
import Colors from 'constants/Colors';
import EditableInput from './EditableInput/EditableInput';
import GuestFormFieldState from 'Components/Dashboard/Forms/GuestForms/GuestFormFieldState';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import UnavailableBadge from './UnavailableInPms/UnavailableInPms';
import { useSupportedFields } from './useSupportedFields';
import useGuestHook from '../../PMS/PMSSelector/Guest/hooks/Guest.hook';
import usePMSUDFConfigHook from '../../PMS/PMSUDFConfig/PMSUDFConfig.hook';
import styles from './GuestTable.style';

export default function GuestTable({
  isLoading,
  data,
  onFieldStateChecked,
  onFieldRequiredChecked,
  selectedLanguage,
  onFieldTextChanged,
  supportedLanguages,
}) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { supportedFields, pmsModuleName } = useSupportedFields();
  const { state: pmsUdfState } = usePMSUDFConfigHook();
  const { snakeToCamel } = useGuestHook();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedType, setSelectedType] = useState('');

  const handleCheckboxClick = (item, type) => {
    const fieldName = snakeToCamel(item.name);
    const normalizedField = fieldName.trim().toLowerCase();
    const pmsName = getPmsName(normalizedField);

    if (!pmsName && shouldShowModal(item, type)) {
      setSelectedItem(item);
      setSelectedType(type);

      let descriptionText;

      if (['uploadDocument1', 'uploadDocument2'].includes(fieldName)) {
        descriptionText = translate('guestTable.unavailableInPMSDescription');
      } else {
        descriptionText = ['Cloudbeds', 'Cloudbeds_new', 'Opera', 'Ohip'].includes(pmsModuleName)
          ? translate('guestTable.requiresMappingDescription')
          : translate('guestTable.unavailableInPMSDescription');
      }

      setModalContent(descriptionText);
      setIsModalVisible(true);
    } else {
      toggleField(item, type);
    }
  };

  const getPmsName = normalizedField => {
    const supportedFieldsMap = new Map(
      supportedFields.map(({ civitfun, pms }) => [civitfun.trim().toLowerCase(), pms])
    );
    let pmsName = supportedFieldsMap.get(normalizedField) || '';

    if (pmsUdfState) {
      pmsUdfState.hotelUdfs.forEach(udf => {
        if (snakeToCamel(udf.validate) === normalizedField) {
          pmsName += pmsName ? ' + ' + udf.name : udf.name;
        }
      });
    }
    return pmsName;
  };

  const shouldShowModal = (item, type) => {
    return (
      (type === 'visible' && item?.state === GuestFormFieldState.DISABLED) ||
      (type === 'required' && item?.required === 0)
    );
  };

  const toggleField = (item, type) => {
    const fieldData = { id: item?.id, fieldHotelId: item?.fieldHotelId };
    if (type === 'visible') {
      onFieldStateChecked(fieldData);
    } else if (type === 'required') {
      onFieldRequiredChecked(fieldData);
    }
  };

  const columns = [
    {
      title: translate('guestTable.fieldCol'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (_, item) => {
        const defaultText = item?.defaultTexts?.find(text => text.lang == selectedLanguage)?.value;
        const formText = item?.formTexts?.find(
          formText => formText.lang == selectedLanguage
        )?.value;

        if (item.name === 'family_relationship' || item.name === 'document_support_number') {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{defaultText || formText}</div>
              <div style={{ marginLeft: 'auto', paddingLeft: '10px' }}>
                <Popover content={translate(item.name)} trigger="hover">
                  <WarningOutlined
                    style={{
                      color: Colors.yellowButton,
                      marginRight: 8,
                      padding: 4,
                      border: `1px solid ${Colors.yellowButton}`,
                      borderRadius: 2,
                      fontSize: 14,
                    }}
                  />
                </Popover>
              </div>
            </div>
          );
        }

        if (item.name.includes('uploadDocument')) {
          return getEditableInput({
            item,
            onValueChanged: onFieldTextChanged,
            supportedLanguages,
            formText,
            defaultText,
          });
        }

        return <div>{formText || defaultText}</div>;
      },
    },
    {
      title: translate('guestTable.fieldColPMS'),
      dataIndex: 'namePms',
      key: 'namePms',
      width: 250,
      render: (_, item) => {
        const fieldName = snakeToCamel(item.name);

        if (supportedFields.length == 0) {
          if (pmsModuleName == 'RoomCloud') {
            return <UnavailableBadge pmsModuleName={pmsModuleName} fieldName={fieldName} />;
          }
          return;
        }

        const normalizedField = fieldName.trim().toLowerCase();

        const supportedFieldsMap = new Map(
          supportedFields.map(({ civitfun, pms }) => [civitfun.trim().toLowerCase(), pms])
        );

        let pmsName = '';
        if (normalizedField === 'nationalityguest' && supportedFieldsMap.has('nationality')) {
          pmsName = supportedFieldsMap.get('nationality');
        } else {
          pmsName = supportedFieldsMap.get(normalizedField);
        }

        // check UDF values
        if (pmsUdfState) {
          pmsUdfState.hotelUdfs.forEach(udf => {
            if (snakeToCamel(udf.validate) == fieldName) {
              if (pmsName) pmsName += ' + ' + udf.name;
              else pmsName = udf.name;
            }
          });
        }

        // documentCountry to the legacy and nationality to the same field but hybrid
        if (pmsModuleName === 'Ohip' && fieldName == 'documentCountry') {
          pmsName = '';
        }
        return (
          <div>
            {pmsName || <UnavailableBadge pmsModuleName={pmsModuleName} fieldName={fieldName} />}
          </div>
        );
      },
    },
    {
      title: null,
      dataIndex: 'desc',
      key: 'desc',
      width: '25%',
      render: (_, item) => {
        if (item?.desc) {
          return <div style={{ color: Colors.red }}>{item.desc}</div>;
        }
        return null;
      },
      responsive: ['xs', 'sm'],
    },
    {
      title: translate('guestTable.visibleCol'),
      dataIndex: 'visible',
      key: 'visible',
      align: 'center',
      render: (_, item) => (
        <Checkbox
          disabled={item?.mandatory}
          checked={item?.state == GuestFormFieldState.ACTIVE}
          onClick={() => handleCheckboxClick(item, 'visible')}
        />
      ),
    },
    {
      title: translate('guestTable.mandatoryCol'),
      dataIndex: 'required',
      key: 'required',
      align: 'center',
      render: (_, item) =>
        item.onlyItaly ? null : (
          <Checkbox
            disabled={item?.mandatory}
            checked={item?.required == 1}
            onClick={() => handleCheckboxClick(item, 'required')}
          />
        ),
    },
  ];

  return (
    <div>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={521}
        height={391}
      >
        <div style={styles.textContainerModal}>
          <div style={styles.iconModal}>
            <ExclamationCircleOutlined />
          </div>
          <div style={styles.titleModal}>
            <strong>{translate('guestTable.activationModal.unavailableInPMSTitle')}</strong>
          </div>
          <div style={styles.textModal}>
            <p>{modalContent}</p>
          </div>
        </div>
        <div style={styles.modalButton}>
          <Button
            key="activate"
            type="primary"
            onClick={() => {
              toggleField(selectedItem, selectedType);
              setIsModalVisible(false);
            }}
            style={styles.activateButton}
          >
            {translate('guestTable.activationModal.activateField')}
          </Button>
        </div>
        <div style={styles.modalButton}>
          <Button key="cancel" onClick={() => setIsModalVisible(false)} style={styles.cancelButton}>
            {translate('guestTable.activationModal.cancel')}
          </Button>
        </div>
      </Modal>

      <Table loading={isLoading} columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

function getEditableInput({ item, onValueChanged, formText, defaultText, supportedLanguages }) {
  const isCustomText = formText?.value !== defaultText?.value || formText?.value == null;

  let hasMissingTranslations = false;
  if (!supportedLanguages || !supportedLanguages.length) return;
  for (const lang of supportedLanguages?.map(item => item.code)) {
    const formText = item?.formTexts?.find(formText => formText.lang == lang);
    const defaultText = item?.defaultTexts?.find(text => text.lang == lang);

    if (!formText || formText?.value === defaultText?.value) {
      hasMissingTranslations = true;
    }
  }

  return (
    <EditableInput
      item={item}
      hint={defaultText?.value}
      value={isCustomText ? formText?.value : ''}
      onValueChanged={onValueChanged}
      hasMissingTranslations={hasMissingTranslations}
    />
  );
}
