import React, { useContext, useState, useEffect } from 'react';
import usePoliceReportConfigHook from './PoliceReportConfig.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import PoliceSFTP from './PoliceSFTP/PoliceSFTP.jsx';
import PoliceSES from './PoliceSES/PoliceSES.jsx';
import PoliceERTZ from './PoliceERTZ/PoliceERTZ.jsx';
import PoliceMOSSOS from './PoliceMOSSOS/PoliceMOSSOS.jsx';
// TODO import PoliceITA from './PoliceITA/PoliceITA.jsx';
import { PoliceReportConfigContext } from './PoliceReportConfig.context';
import Colors from 'constants/Colors.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import AppSelector from 'Components/common/AppSelector/AppSelector';

export default function PoliceReportConfig() {
  const { state, actions } = usePoliceReportConfigHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);
  
  const [selectedOption, setSelectedOption] = useState(state.platform || null);
  const policeReportOptions = [
    { label: translate('policeReport.ses.ses'), value: 'ses' },
    { label: translate('policeReport.ertz.ertz'), value: 'ertz' },
    { label: translate('policeReport.mossos.mossos'), value: 'mossos' },
    //TODO { label: translate('policeReport.ita'), value: 'ITA' },
  ];
  
  const policeReportSelectValue = option => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (state.platform) {
      setSelectedOption(state.platform);
    }
  }, [state.platform]);

  const renderContent = () => {
    const platform = selectedOption ?? state.platform;
   
    switch (platform) {
      case 'ses':
        return <PoliceSES />;
  
      case 'ertz':
         return <PoliceERTZ />;
      
      case "mossos":
        return <PoliceMOSSOS />;
      //TODO
      // case 'ita':
      //   return <PoliceITA />;

      default:
        return null;
    }
  };
  

  return (
    <DefaultLayout title={translate('layout.policeReportConfig')} enableAllHotels={false}>
      <PoliceReportConfigContext.Provider value={{ state, actions }}>
        {state.suscription ? (
          <>
            <ContentBox title={translate('policeReport.sftpConfig')}>
              <PoliceSFTP />
            </ContentBox>

            <ContentBox title={translate('policeReport.whoDoIReportTo')}>
              <AppSelector
                title={translate('Selecciona una plataforma')}
                value={selectedOption}
                options={policeReportOptions}
                onOptionSelected={option => policeReportSelectValue(option)}
              />
              {renderContent()}
            </ContentBox>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: Colors.white,
                borderRadius: 16,
                padding: 16,
                maxWidth: 600,
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                {translate('policeReport.missingSuscription.title')}
              </div>
              <div>{translate('policeReport.missingSuscription.description')}</div>
              {state.sendSuscriptionRequestEnabled && (
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
                  <AppButton
                    text={translate('policeReport.missingSuscription.send')}
                    action={() => actions.requestGuestlinkFunctionality()}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </PoliceReportConfigContext.Provider>
    </DefaultLayout>
  );
}
