import React, { useContext } from 'react';
import useGuestlinkConfigHook from './GuestlinkConfig.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import { GuestlinkConfigContext } from './GuestlinkConfig.context.js';
import SmtpConfig from './SmtpConfig/SmtpConfig.jsx';
import GmailConfig from './GmailConfig/GmailConfig.jsx';
import RequestSuscription from '../../../common/RequestSuscription/RequestSuscription.jsx';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';

export default function GuestlinkConfig() {
  const { state, actions } = useGuestlinkConfigHook();
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const integrationOptions = [
    { value: 'gmail', label: 'Gmail' },
    { value: 'others', label: translate('guestlink.integrationTypeOthers') },
  ];

  return (
    <DefaultLayout>
      <GuestlinkConfigContext.Provider value={{ state, actions }}>
        <ContentBox singleBox>
          {state.suscription ? (
            <>
             <AppSelector
              style={{ width: '100%', marginBottom: '20px' }}
              title={translate('guestlink.selectIntegrationType')}
              value={state.integrationType}
              options={integrationOptions}
              onOptionSelected={actions.setIntegrationType}
            />

              {state.integrationType === 'gmail' && (
                <GmailConfig />
              )}

              {state.integrationType === 'others' && <SmtpConfig />}
            </>
          ) : (
            <RequestSuscription suscription="guestlink" />
          )}
        </ContentBox>
      </GuestlinkConfigContext.Provider>
    </DefaultLayout>
  );
}