import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function usePoliceReportConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  
  const [state, setState] = useState({
    isLoading: false,
    ftpHost: null,
    ftpUser: null,
    ftpPass: null,
    fileFormat: null,
    filePrefix: null,
    fileSufix: null,
    ftpDirPath: null,
    sesUser: null,
    sesPass: null,
    platform: null,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    ftpFiles: 0,
    ftpErrors: 0,
    ftpResult: [],
    testFile: null,
    isValidation: false,
    isFileList: false,
    fileContent: '',
  });

  // let mockValidations = [
  //   {
  //     message: "Line 178: Field 'paternalSurname' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 179: Field 'idDate' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 179: Field 'firstName' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 179: Field 'paternalSurname' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 180: Field 'idDate' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 180: Field 'firstName' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 180: Field 'paternalSurname' is required but missing",
  //     error: 'error',
  //   },
  //   {
  //     message: "Line 190: Field 'idDate' is required but missing",
  //     error: 'warning',
  //   },
  //   {
  //     message: "Line 190: Field 'paternalSurname' is required but missing",
  //     error: 'error',
  //   },
  // ];

  useEffect(() => {
    actions.loadPoliceReportConfig();
  }, [appState.selectedHotel]);

  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          const possibleFormats = ['YYYY-MM-DD', 'YYYYMMDD', 'DD-MM-YYYY', 'DDMMYYYY'];
          const file = res?.fileFormat || '';
          const extension = file ? file.substring(file.lastIndexOf('.') + 1) : '';
          const fileWithoutExtension = file.replace(`.${extension}`, '');

          let detectedFormat = possibleFormats.find((fmt) => fileWithoutExtension.includes(fmt));

          if (!detectedFormat) {
            detectedFormat = 'YYYY-MM-DD';
          }

          const filePrefix = fileWithoutExtension.substring(0, fileWithoutExtension.indexOf(detectedFormat));
          const fileSuffix = fileWithoutExtension.substring(
            fileWithoutExtension.indexOf(detectedFormat) + detectedFormat.length
          );

          updateState({
            isLoading: false,
            ftpHost: res?.ftpHost,
            ftpUser: res?.ftpUser,
            ftpPass: res?.ftpPass,
            ftpPort: res?.ftpPort,
            fileFormat: `${detectedFormat}`,
            filePrefix: filePrefix,
            fileSufix: fileSuffix,
            sesUser: res?.sesUser,
            sesPass: res?.sesPass,
            ftpDirPath: res?.ftpDirPath,
            extension: extension,
            lessorCode: res?.lessorCode,
            establishmentCode: res?.establishmentCode,
            platform: res?.platform,
            suscription: res?.suscription === 'active',
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onCheckConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSFTPConnection({
        hotelId: getSelectedHotel()?.key,
        host: state.ftpHost,
        username: state.ftpUser,
        password: state.ftpPass,
        port: parseInt(state.ftpPort),
        folder: state.ftpDirPath,
      })
      .then(_ => {
        updateState({ isLoading: false });
        notification.success({
          message: translate('policeReport.checkConnectionSuccess'),
        });
      })
      .catch(err => {
        console.warn(err);
        updateState({ isLoading: false });
        notification.error({
          message: translate('policeReport.checkConnectionError'),
        });
      });
    },

    validateFileExport: () => {
      updateState({ isLoading: true });
      API.policeReportValidateFileExport({
        hotelId: getSelectedHotel()?.key,
        host: state.ftpHost,
        username: state.ftpUser,
        password: state.ftpPass,
        port: parseInt(state.ftpPort),
        folder: state.ftpDirPath,
        platform: 'SES',
        file: state.testFile,
      })
        .then(res => {
          const fileContent = res.fileContent || '';
          const errors = res.errors || [];

          if (errors.length > 0) {
            const errorItems = errors.filter(item => item.error === 'error');
            const otherItems = errors.filter(item => item.error !== 'error');

            const sortedValidations = [...errorItems, ...otherItems];
            updateState({
              isLoading: false,
              ftpErrors: sortedValidations,
              ftpResult: errors && errors.length > 0 ? errors : [],
              isValidation: true,
              isFileList: false,
              fileContent: fileContent,
            });
          } else {
            updateState({ isLoading: false, ftpErrors: [], ftpResult: [], isValidation: true, isFileList: false, fileContent: fileContent });
          }
          notification.success({
            message: translate('policeReport.validateFileExportSuccess'),
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false, ftpErrors: [], ftpFiles: [], ftpResult: [], isValidation: true, isFileList: false });
          notification.error({
            message: translate('policeReport.validateFileExportError'),
          });
        });
    },


    onCheckSESConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSESConnection({
        hotelId: getSelectedHotel()?.key,
        sesCredentials: {
          username: state.sesUser,
          password: state.sesPass,
          lessorCode: state.lessorCode,
          establishmentCode: state.establishmentCode,
        },
      })
        .then(result => {
          updateState({ isLoading: false });

          if (result.success) {
            notification.success({
              message: translate('policeReport.checkSESConnectionSuccess'),
            });
          } else {
            notification.error({
              message: translate('policeReport.checkSESConnectionError'),
            });
          }
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.checkSESConnectionError'),
          });
        });
    },

    onSaveFTPConfig: () => {
      updateState({ isLoading: true });
    
      if (state.extension !== 'txt' && state.extension !== 'xml') {
        actions.setField('extension', 'txt');
        state.extension = 'txt';
      }
    
      if (state.fileSufix.endsWith('.')) {
        state.fileSufix = state.fileSufix.replace(/\.+$/, '');
      }

      const possibleFormats = ['YYYY-MM-DD', 'YYYYMMDD', 'DD-MM-YYYY', 'DDMMYYYY'];
      if ( !possibleFormats.includes(state.fileFormat) ) {
        state.fileFormat = 'YYYY-MM-DD';
        actions.setField('fileFormat', 'YYYY-MM-DD');
      }

      API.updatePoliceSFTP({
        hotelId: getSelectedHotel()?.key,
        ftpConfig: {
          host: state.ftpHost,
          user: state.ftpUser,
          password: state.ftpPass,
          port: state.ftpPort,
        },
        fileFormat: `${state.fileFormat}.${state.extension || 'txt'}`,
        filePrefix: state.filePrefix,
        fileSufix: state.fileSufix,
        ftpDirPath: state.ftpDirPath,
      })
        .then(() => {
          API.policeReportCheckSFTPConnection({
            hotelId: getSelectedHotel()?.key,
            host: state.ftpHost,
            username: state.ftpUser,
            password: state.ftpPass,
            port: parseInt(state.ftpPort),
            folder: state.ftpDirPath,
          })
            .then(res => {
              updateState({
                isLoading: false,
                ftpFiles: res && res.length > 0 ? res : [],
                isValidation: false,
                isFileList: true,
              });
              notification.success({
                message: translate('policeReport.checkConnectionSuccess'),
              });
            })
            .catch(err => {
              console.warn(err);
              updateState({
                isLoading: false,
                ftpFiles: [],
                ftpResult: [],
                isValidation: false,
                isFileList: true,
              });
              notification.error({
                message: translate('policeReport.checkConnectionError'),
              });
            });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.ftpError'),
          });
        });
    },
    
    onSaveSESConfig: () => {
      updateState({ isLoading: true });
    
      API.updatePoliceSES({
        hotelId: getSelectedHotel()?.key,
        sesCredentials: {
          user: state.sesUser,
          password: state.sesPass,
        },
        lessorCode: state.lessorCode,
        establishmentCode: state.establishmentCode,
      })
        .then(() => {
          if (state.platform  === 'ses' || true) { //FIXME
            API.policeReportCheckSESConnection({
              hotelId: getSelectedHotel()?.key,
              sesCredentials: {
                username: state.sesUser,
                password: state.sesPass,
                lessorCode: state.lessorCode,
                establishmentCode: state.establishmentCode,
              },
            })
              .then(result => {
                updateState({ isLoading: false });
    
                if (result.success) {
                  notification.success({
                    message: translate('policeReport.checkSESConnectionSuccess'),
                  });
                } else {
                  notification.error({
                    message: translate('policeReport.checkSESConnectionError'),
                  });
                }
              })
              .catch(err => {
                console.warn(err);
                updateState({ isLoading: false });
                notification.error({
                  message: translate('policeReport.checkSESConnectionError'),
                });
              });
          } else {
            updateState({ isLoading: false });
            notification.success({
              message: translate('policeReport.ertzSuccess'),
            });
          }
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.sesError'),
          });
        });
    },
    
    requestGuestlinkFunctionality: () => {
      updateState({ isLoading: true });
      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription: 'police_report',
        functionality: 'Police Report Module',
      })
        .then(_ => {
          actions.sendRequestPoliceFunctionalityEmail();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestPoliceFunctionalityEmail: () => {
      updateState({ isLoading: true });
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality: 'Police Report Module',
      })
        .then(_ => {
          notification.success({
            message: translate('policeReport.missingSuscription.requestSent'),
          });
          updateState({ isLoading: false, sendSuscriptionRequestEnabled: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    setField: (fieldKey, value) => {
      if (fieldKey === 'extension' && value.includes('.')) {
        value = value.replace('.', '');
      }

      updateState({ [fieldKey]: value });
    },

    resetCredentials: platform => {
      state.platform = platform;
      state.sesUser = null;
      state.sesPass = null;
      state.establishmentCode = null;
      state.lessorCode = null;
    },

    loadInitCredentials: (sesUser, sesPass, establishmentCode, lessorCode) => {
      state.sesUser = sesUser;
      state.sesPass = sesPass;
      state.establishmentCode = establishmentCode;
      state.lessorCode = lessorCode;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
