import React from 'react';
import { Table } from 'antd';
import useGuestHook from './hooks/Guest.hook';
import guestStyles from './Guest.styles';

export default function ResponseTable({ data, translate }) {
  const variableName = translate('validation.guest.response.variableName');
  const civitfunData = translate('validation.guest.response.civitfunData');
  const pmsData = translate('validation.guest.response.pmsData');
  const { snakeToCamel, snakeToCapitalizedWithSpace } = useGuestHook();

  const columns = [
    {
      title: variableName,
      dataIndex: 'key',
      key: 'key',
      render: text => (
        <div style={guestStyles.keyColumn}>{snakeToCapitalizedWithSpace(snakeToCamel(text))}</div>
      ),
    },
    {
      title: civitfunData,
      dataIndex: 'civitfunData',
      key: 'civitfunData',
      render: (text, record) => (
        <div style={record.isDifferent ? guestStyles.valueColumnDiferent : guestStyles.valueColumn}>
          {text}
        </div>
      ),
    },
    {
      title: pmsData,
      dataIndex: 'pmsData',
      key: 'pmsData',
      render: (text, record) => (
        <div style={record.isDifferent ? guestStyles.valueColumnDiferent : guestStyles.valueColumn}>
          {text}
        </div>
      ),
    },
  ];

  const dataSource = data.mockGuests[0].fields.map((field, index) => {
    const differences = data.differences[0]?.differences;
    const arrDiferences = differences ? Object.keys(differences) : [];
    const arrDiferencesSnakeCase = arrDiferences.map(difference => snakeToCamel(difference));
    const isDifferent = arrDiferencesSnakeCase.includes(snakeToCamel(field.key));

    return {
      key: field.key,
      civitfunData: isDifferent ? differences[snakeToCamel(field.key)].mockedGuest : field.value,
      pmsData: isDifferent ? differences[snakeToCamel(field.key)].pmsGuest : field.value,
      isDifferent: isDifferent,
    };
  });

  return (
    <Table columns={columns} dataSource={dataSource} pagination={false} style={guestStyles.table} />
  );
}
