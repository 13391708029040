import axios from 'axios';
import apiConfig from '../../api.config';

export default function uploadERTZFile({
  hotelId,
  file,
}) {
  const {
    host,
    URIs: { policeReport, policeReportUploadERTZFile },
  } = apiConfig;

  const uri = `${host}${policeReport}/${policeReportUploadERTZFile.replace(':hotelId', hotelId)}`;

  const formData = new FormData();
  formData.append('file', file);

  return axios
    .put(uri, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',  
      },
    })
    .then(res => {
      return res.data;
    })

}
