import React from 'react';
import styles from './AppButton.module.css';

export default function AppButton({
  text,
  action,
  secondary,
  setShowPopConfirm,
  applyToAllHotels = false,
  icon,
  disabled,
  secondayIcon,
  redButton = null,
  style = {},
  ...props
}) {
  let buttonStyle = {};

  if (redButton) {
    buttonStyle = {
      backgroundColor: '#B42318',
      borderColor: '#FDA29B',
      color: 'white',
    };
  }

  if (secondary) {
    buttonStyle = {
      backgroundColor: '#F0F3F5',
      color: '#708393',
    };
  }

  const finalStyle = {
    ...buttonStyle, // Aplica los estilos específicos de `redButton` o `secondary`
    ...style, // Aplica los estilos adicionales pasados como props
    ...(disabled ? { opacity: '0.5', pointerEvents: 'none' } : {}),
  };

  return (
    <div
      className={styles.buttonContainer}
      style={finalStyle}
      {...props}
      onClick={() => {
        if (applyToAllHotels) {
          setShowPopConfirm ? setShowPopConfirm(true) : action(applyToAllHotels);
        } else {
          action && !disabled && action(applyToAllHotels);
        }
      }}
    >
      {icon && icon}
      {text && (
        <b
          style={{
            textAlign: 'center',
            color: buttonStyle.color, // Asegura que el color del texto sea el correcto
          }}
          unselectable="true"
        >
          {text}
        </b>
      )}
      {secondayIcon && secondayIcon}
    </div>
  );
}
