export default {
  layout: {
    configuration: 'Configurazione',
    dashboard: 'Dashboard',
    hotels: 'Hotels',
    forms: 'Forms',
    appearence: 'Appearence',
    pms: 'PMS',
    sales: 'Canali di check-in',
    payment: 'Canali di pagamento',
    documents: 'Documenti legali',
    language: 'Lingue e letterali',
    users: 'Team',
    checkin: 'Check-in',
    checkout: 'Check-out',
    booking: 'Prenotazioni',
    paymentMethods: 'Metodi di pagamento',
    billing: 'Informazioni di fatturazione',
    subscriptions: 'Abbonamenti',
    doorOpening: 'Apertura della porta',
    stats: 'Riepilogo',
    account: 'Il mio account',
    communicationChannels: 'Modelli e-mail',
    accountDetail: 'Dettagli account',
    marketing: 'Marketing',
    support: 'Supporto',
    upsell: 'Upselling',
  },
  save: 'Salva',
  cancel: 'Annulla',
  search: 'Cerca',
  add: 'Aggiungi',
  yes: 'Sì',
  no: 'No',
  setup: 'Connetti',
  checkinPreview: 'Checkin Preview',
  logout: 'Esci',
  state: 'Stato',
  hotel: 'Hotel',
  pms: 'PMS',
  id: 'ID',
  invite: 'Invito',
  pmsPopup: {
    title: '¡Perfetto!',
    subtitle: 'Avete appena registrato la vostra proprietà',
    legalTextNewHotel:
      'La Parte di Iscrizione e il Testo Legale della Parte di Iscrizione sono stati salvati correttamente in Inglese e Spagnolo',
    text: 'Ora potete ottimizzare il processo collegando il vostro PMS',
    button: 'Impostazione PMS',
  },
  hotelSelector: {
    onlyTo: 'Solo per:',
    applyToAllHotels: 'Tutti gli hotel',
    allHotels: 'Tutti gli hotel',
    hint: "Inserisci il nome dell'hotel",
    info: 'Seleziona hotel:',
  },
  channel: 'Canale',
  checkins: 'Checkins',
  appearence: {
    checkInTitle: 'Aspetto del check-in online',
    logo: 'Logo',
    mainColor: 'Colore principale',
    secondaryColor: 'Colore secondario',
    bgColor: 'Colore di sfondo',
    kioskTitle: 'Aspetto del chiosco',
    mainColorKiosk: 'Colore principale del chiosco',
    kioskBg: 'Colore di sfondo del chiosco',
    kioskLogo: 'Logo del chiosco',
    success: 'Aspetto aggiornato con successo',
    languageSuccess: 'Lingue aggiornate con successo',
    apply: 'Applica stili',
    languageTitle: 'Selezionare le lingue desiderate',
  },
  login: {
    header: 'Inserisci i tuoi dati di accesso',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    loginButton: 'Login',
    registerButton: 'Crea un nuovo account',
    forgotPassword: 'Hai dimenticato la password?',
  },
  register: {
    title: 'Informazioni sul conto',
    chain: 'Nome della catena alberghiera',
    name: 'Nome',
    phone: 'Telefono',
    mail: 'Email',
    password: 'Password',
    repeatPassword: 'Ripeti password',
    termsConditions: "Ho letto e accetto l'informativa sulla privacy e i termini e condizioni",
    button: 'Crea nuovo account',
    emptyFields: 'È necessario compilare tutti i campi del modulo',
    matchPasswords: 'Le password non corrispondono',
    invalidMail: "L'indirizzo e-mail non è valido",
    termsError: 'È necessario accettare i termini e le condizioni',
    registerOk:
      "Benvenuto su Civitfun, ti abbiamo inviato un'e-mail per convalidare la tua registrazione",
    goBack: 'Torna al login',
  },
  recover: {
    header:
      "Inserisci l'indirizzo e-mail associato al tuo account e ti invieremo un'e-mail per recuperare la password",
    invalidEmail: 'Inserisci un indirizzo e-mail valido',
    send: 'Invia',
    success: 'Abbiamo inviato per e-mail il link per la reimpostazione della password!',
  },
  changePassword: {
    header: 'Inserisci la tua nuova password',
    success: 'La password è stata modificata con successo',
  },
  validateUser: {
    success: '# la registrazione è stata completata con successo!',
  },
  bookingList: {
    nameAndSurnameCol: 'Nome e cognome',
    locatorCol: 'Locator',
    nameCol: 'Nome',
    surnameCol: 'Cognome',
    checkinCol: 'Checkin',
    checkoutCol: 'Checkout',
    adultCol: 'Adulti',
    childrenCol: 'Bambini',
    documentCol: 'Documento',
    export: 'Esporta dati',
    import: 'Importa prenotazioni',
    title: 'Prenotazioni',
    statusCol: 'Stato',
    actionPop: 'Vedi i checkin correlati',
  },
  bookingStatus: {
    BOOKED: 'Prenotato',
    UNCOMPLETED: 'Non completato',
    COMPLETED: 'Completato',
    CHECKEDOUT: 'Checked Out',
    CHECKEDIN: 'Checked In',
  },
  setUp: {
    searchFieldsTitle: 'Ricerca nei campi del modulo',
    searchFieldInfo:
      'Se il processo di check-in è integrato con il PMS, questi campi saranno configurati automaticamente',
    checkInRequirement: 'Requisiti del processo di check-in',
  },
  guestTable: {
    fieldCol: 'Civitfun',
    fieldColPMS: 'PMS',
    visibleCol: 'Visible',
    mandatoryCol: 'Obbligatorio',
    success: 'La configurazione del modulo ospite è stata aggiornata',
    error: 'Non è stato possibile aggiornare la configurazione del modulo per gli ospiti',
    applyToAllWarning: 'La configurazione sarà applicata a tutti gli hotel',
    unavailableInPMS: 'Non disponibile nel PMS',
  },
  checkInList: {
    stateCol: 'Stato',
    locatorCol: 'Locator',
    typeCol: 'Tipo',
    nameCol: 'Nome',
    surnameCol: 'Cognome',
    checkinCol: 'CheckIn',
    checkoutCol: 'CheckOut',
    documentCol: 'Documento',
    title: 'CheckIn',
    export: 'Esportazione dati',
    statePending: 'In attesa',
    stateCheckin: 'Check In',
    stateCheckout: 'Check Out',
    actions: 'Dettaglio',
    channelCol: 'Canale',
    checkinDate: 'Check In',
    detail: {
      bookingDetail: 'Dettagli della prenotazione',
      informationTitle: 'Informazioni',
      documentsTitle: 'Documenti',
      locator: 'Locator',
      checkin: 'Checkin',
      checkout: 'Checkout',
      holder: 'Titolare',
      signedDocuments: 'Documenti firmati',
      termsConditions: 'Termini e condizioni',
      cardex: 'Rapporto di checkin',
      guestDetail: 'Dati del titolare',
      paymentDetail: 'Dettagli pagamento',
      payment: {
        charges: 'spese',
        operations: 'Operazioni',
        locator: 'Localizzatore',
        amount: 'Importo',
        operation: 'Tipo',
        concept: 'Concetto',
        paymentStatus: 'Stato del pagamento',
        warrantyStatus: 'Stato della garanzia:',
        statusDivider: 'Stato dei pagamenti',
        totalBalance: 'Saldo totale',
        operationPayment: 'Pagamento',
        operationTokenize: 'Garanzia',
        operationPaymentTokenize: 'Pagamento e garanzia',
      },
    },
  },
  ihClient: {
    CIVITFUN: 'Civitfun',
    MTS: 'MTS',
    ROOMMATIK: 'Roommatik',
    BOOKINGDOTCOM: 'Booking.com',
    KIOSK: 'Auto Check-In Terminal',
    RECEPTION: 'Terminal senza carta',
  },
  salesChannels: {
    stateCol: 'Stato',
    hotelCol: 'Hotel',
    channelCol: 'Canale',
    actionCol: 'Azione',
    identifierCol: 'Identificatore',
    bookingIdHint: 'Id. hotel Booking.com',
    bookingTooltip:
      "Che cos'è? Puoi trovare l'ID della tua struttura su Booking.com nel tuo pannello di amministrazione",
    tryFreeButton: 'Prova gratis',
    success: 'Il canale di check-in è stato aggiornato con successo!',
    otaPopup: {
      title: 'Perfetto!',
      text: 'Tutto ciò che devi fare è attivare il canale $OTA per iniziare a ricevere i check-in',
      button: 'Attiva $OTA',
    },
    activeGuestlink: 'Per abilitare Booking.com devi configurare il tuo SMTP',
  },
  guestForms: {
    holder: 'Titolare',
    adults: 'Adulti',
    children: 'Bambini',
    applyToAllHotels: 'Applica a tutti gli hotel',
  },
  hotels: {
    addNewHotel: 'Aggiungi un nuovo hotel',
    title: 'Hotel',
    nameCol: 'Nome',
    editCol: 'Modifica',
    deleteCol: 'Elimina',
    deleteWarningTitle: 'Elimina',
    deleteWarning: "Vuoi eliminare l'hotel?",
    mapHint: "Trova l'hotel sulla mappa",
    country: 'Paese',
    address: 'Indirizzo',
    province: 'Provincia',
    addSuccess: 'Hotel # è stato aggiunto',
    updateSuccess: "L'hotel # è stato aggiornato con successo",
    deleteSuccess: "L'hotel # è stato rimosso",
    previewCheckin: 'Il mio check-in online',
    integrated: 'Integrato',
    noIntegrated: 'Non integrato',
    emptyTitle: 'Aggiungi i tuoi hotel',
    emptyDescription:
      "Per farlo, basta compilare il campo del nome e cercare l'indirizzo sulla mappa",
    emptyName: "È necessario aggiungere un nome per l'hotel",
    emptyLocation: "È necessario aggiungere una posizione per l'hotel",
  },
  legalDocuments: {
    termsConditions: 'Termini e condizioni',
    checkIn: 'Rapporto di checkin',
    commercialUse: 'Testo check-box uso commerciale dei dati',
    preview: 'Anteprima',
    previewBtn: 'Anteprima',
    success: 'Il testo è stato aggiornato con successo',
    error: "Si è verificato un errore durante l'aggiornamento del testo",
    warning:
      'Modelli caricati per impostazione predefinita. Non verranno registrati finché non li salvi',
    previewPlaceholder: 'Fare clic su "anteprima" per vedere un\'anteprima del documento',
    langSelectorHint: 'Selezionare la lingua dei documenti',
    terms: 'Termini e condizioni',
    restoreDefault: 'Ripristina',
    restoreDefaultWarning: 'I dati non salvati andranno persi. Volete continuare?',
    cardex: 'Rapporto di check-in',
    labelHint: "L'etichetta o il testo accanto alla casella di controllo",
    'data-privacy-policy': 'Informativa sulla privacy',
    'loyalty-program': 'Programma fedeltà',
    'data-commercial-use': 'Dati per uso commerciale',
    custom_cardex_pdf: 'custom_cardex_pdf',
    checkin_template: 'Modello di mail di conferma del check-in',
    checkout_template: 'Modello di mail di conferma del check-out',
    tags: {
      chain: 'Chain',
      chain_logo: 'Logo della catena',
      chain_name: 'Nome della catena',
      hotel: 'Hotel',
      hotel_logo: "Logo dell'hotel",
      hotel_name: "Nome dell'hotel",
      address: 'Indirizzo',
      hotel_phone: "Numero di telefono dell'hotel",
      CIF: "CIF aziendale dell'hotel",
      CIF_postal_code: "Codice postale dell'azienda alberghiera",
      CIF_address: "Indirizzo dell'azienda dell'hotel",
      CIF_city: "Città dell'azienda alberghiera",
      CIF_province: "Provincia dell'azienda alberghiera",
      CIF_country: "Paese dell'azienda alberghiera",
      CIF_business_name: "Nome della società dell'hotel",
      CIF_business_email: "Email di contatto dell'azienda alberghiera",
      legal_cardex: 'Testo legale sulla parte della voce',
      legal_terms_and_conditions: 'Testo legale sul contratto di proprietà',
      legal_data_privacy_policy: 'Testo legale sulle politiche di privacy dei dati',
      legal_data_commerial_use: "Testo legale sull'uso commerciale dei dati",
      legal_loyalty_program: "Testo legale sull'adesione al programma fedeltà",
      booking: 'Prenotazione',
      reference_number: 'locator/voucher/voucher',
      booking_identifier: 'identificatore unico nel PMS',
      entrance_date: 'Data di ingresso',
      departure_date: 'Data di partenza',
      entrance_time: 'Ora di ingresso',
      departure_time: 'orario di partenza',
      nights: 'Numero di notti',
      adults: 'Numero di adulti',
      children: 'numero di bambini',
      num_guests: 'numero totale di ospiti',
      email: 'email della prenotazione',
      balance: 'prezzo',
      agreed_price: 'prezzo concordato',
      booking_room: 'numero di camere',
      room_number: 'nome della camera assegnata',
      room_number_name: 'nome della camera',
      customer_notes: 'note del cliente',
      agency_code: 'codice agenzia',
      regime_stay: 'regime di soggiorno',
      guest_name: "nome dell'ospite",
      guest_nationality: "nazionalità dell'ospite",
      document_number: 'numero del documento del titolare',
      holder_signature: 'firma del titolare',
      police_report_number: 'numero del rapporto di polizia',
      guest: 'ospite',
      guest_first_name: 'Nome',
      guest_surname: 'Cognome',
      guest_second_surname: 'Secondo cognome',
      guest_gender: 'Genere',
      guest_birth_date: 'Data di nascita',
      guest_nationality: 'Nazionalità',
      guest_document_type: 'Tipo di documento',
      guest_document_number: 'Numero del documento',
      guest_expedition_date: 'Data della spedizione',
      guest_expiration_date: 'Data di scadenza',
      guest_email: 'Email',
      guest_phone: 'Telefono',
      guest_address: 'Indirizzo',
      guest_postal_code: 'Codice postale',
      guest_city: 'Città',
      guest_country: 'Paese',
      guest_signature: 'Firma',
      data_privacy_policy: 'Informativa sulla privacy',
      data_commercial_use: 'Utilizzo dei dati',
      loyalty_program: 'Programma fedeltà',
      credit_card: 'Carta di credito',
      card_name: 'Nome della carta',
      card_type: 'Tipo di carta',
      card_number: 'Numero di carta',
      card_expiration_date: 'Data di scadenza',
      guest_allow_telephone_contact: 'Consenso al contatto telefonico',
    },
  },
  literals: {
    title: 'Compila questi campi per personalizzare i tuoi testi',
  },
  stats: {
    total: 'Totale',
    avgDuration: {
      title: 'Tempo medio di check-in',
      subtitle: "Tempo medio impiegato dall'ospite per effettuare il check-in",
      noData: 'Nessun dato',
    },
    conversion: {
      title: 'Conversione',
      subtitle: 'Checkin iniziati VS checkin finiti',
      noData: 'Nessun dato',
    },
    mostCheckins: {
      title: 'Giorno e ora con più check-in',
      subtitle:
        'Gli utenti effettuano il check-in più frequentemente nei giorni e nelle ore del giorno',
      noData: 'Nessun dato',
    },
    avanzare: {
      title: 'Checkin anticipato',
      subtitle:
        'Gli utenti effettuano il check-in in media un giorno prima della data del check-in',
      noData: 'Nessun dato',
      days: 'Giorni',
      hours: 'Ore',
    },
    devices: {
      title: 'Dispositivi principali',
      phone: 'Telefono',
      tablet: 'Tablet',
      desktop: 'Computer',
      unknown: 'N/A',
    },
  },
  pmsScreen: {
    title: 'CONFIGURAZIONE PMS',
    defaultSelectorValue: 'Selezionare un PMS',
    connectSuccess: 'PMS configurato correttamente!',
    connectError: 'Non è stato possibile connettersi al PMS. Riprovare più tardi',
  },
  states: {
    active: 'Attivo',
    inactive: 'Inattivo',
    enabled: 'Abilitato',
    disabled: 'Disabilitato',
    pending: 'In attesa',
  },
  view: 'Vista',
  status: 'Stato',
  checkinConfig: {
    allowed_since: 'Da quante ore prima della data del check-in è consentito il check-in online?',
    allowed_since_desc: 'Lasciare 0 se non si desidera limitare il check-in',
    allowed_until:
      'Quante ore prima della data di check-in è consentito effettuare il check-in online?',
    allowed_until_desc: 'Lasciare a 0 se non si vuole limitare',
    required_documents: "Per chi è richiesta l'acquisizione dei dati su base obbligatoria?",
    required_signatures: 'Chi deve firmare il contratto di proprietà?',
    required_cardex_signatures: 'Chi deve firmare il rapporto di checkin?',
    capture_docs: 'Configurazione scanner documenti: ',
    success: 'Configurazione aggiornata con successo',
    checkinWarning: 'La configurazione del checkin verrà aggiornata',
    chainCheckinWarning: 'La configurazione verrà aggiornata per tutti gli hotel della catena',
    bookingCode: 'Codice di prenotazione',
    bookingHolder: 'Titolare della prenotazione',
    entranceDate: 'Data di check-in',
    nights: 'Notti',
    save_cardex: 'Chi deve salvare e inviare per email il contratto della proprietà?',
    send_cardex: '',
    save_contract: "Chi deve salvare e inviare per email il registro d' entrata?",
    send_contract: '',
    options: {
      o_0: 'Nessuno',
      o_1: 'Uno',
      o_1_in_last_process: "Uno, nell'ultimo processo",
      o_holder: 'Titolare',
      o_num_adults: 'Tutti gli adulti',
      o_num_guests: 'Tutti gli ospiti',
      o_guest_over_10: 'Tutti gli adulti e i bambini di età superiore a 10 anni',
      o_guest_over_12: 'Tutti gli adulti e i bambini di età superiore ai 12 anni',
      o_guest_over_14: 'Tutti gli adulti e i bambini di età superiore ai 14 anni',
      o_guest_over_16: 'Tutti gli adulti e i bambini di età superiore ai 16 anni',
      o_optional: 'Opzionale',
      o_required: 'Obbligatorio',
      o_payment_warranty: 'Pagamento e garanzia',
      o_payment: 'Pagamento',
      o_warranty: 'Garanzia',
      o_1_taxes: 'Tasse',
      o_optional_taxes: 'Opzionale con le tasse',
      o_active: 'Sì',
      o_disabled: 'No',
      capture_docs_optional: 'Opzionale',
      capture_docs_disabled: 'Disabilitato',
      capture_docs_mandatory: 'Obbligatorio',
    },
  },
  superUser: {
    selectAccount: 'Select Account',
    changeAccount: 'Cambia conto',
    accountName: 'Nome del conto',
    hotelName: "Nome dell'hotel",
    access: 'Accedi',
  },
  view: 'Vista',
  status: 'Stato',
  communicationChannels: {
    documentsBoxTitle: 'Puoi personalizzare le tue e-mail in modo molto semplice',
    emailsSuccess: 'Gli indirizzi e-mail sono stati aggiornati',
    senderCheckinEmailHeader: 'Mittente:',
    emailCheckinHeader: 'Invia come cc a:',
    addNewMail: 'Aggiungi posta',
  },
  emptyHotelListError:
    'Per utilizzare questa funzionalità è necessario disporre di un hotel. È possibile aggiungerli dalla sezione "Hotel"',
  user: {
    updateSuccess: "I dati dell'utente sono stati aggiornati correttamente",
    updateError: "Errore nell'aggiornamento dei dati utente",
    newPassword: 'Nuova password',
    currentPassword: 'Password attuale',
    emptyFields: 'I campi non possono essere vuoti',
    passwordSuccess: 'La password è stata modificata',
    sendInvitationWarning: "Verrà inviata un'e-mail di invito a #. Desidera continuare?",
    sendInviteSuccess: "È stata inviata un'e-mail per cambiare la password al nuovo utente",
    emptyPhone: 'Il numero di telefono non può essere vuoto',
    accountDetailTitle: "Proprietario dell'account",
    changePasswordTitle: 'Modifica della password',
  },
  marketing: {
    buttonGenerator: 'Generatore di pulsanti web',
    hotelCheckin: 'Hotel Check-In',
    chainCheckin: 'Chain Check-In',
    links: 'Collegamenti',
    selectHotel: 'Seleziona un hotel',
    example: 'Pulsante di esempio:',
    buttonText: 'Check-in online',
    deeplink: 'DEEPLINK: Aumenta la conversione dei tuoi check-in online',
    option: 'Opzione',
    comingSoon: 'Prossimamente',
    deeplink1desc:
      "Utilizzate i parametri di base nell'URL delle e-mail di check-in online dei vostri ospiti per evitare azioni di ricerca. Non appena il vostro hotel sarà attivo, i vostri ospiti potranno effettuare il check-in online senza cercare la prenotazione. Potete integrare il link nei modelli di pre-soggiorno aggiungendo i parametri necessari per eseguire la ricerca",
    deeplink2desc:
      'Utilizzate il [BOOKING_IDENTIFIER] per generare un link unico per ogni prenotazione',
    bookingCodeTitle: "Che cos'è il CODICE DI PRENOTAZIONE?",
    bookingCodeDesc:
      'È il localizzatore che il vostro ospite ha ricevuto al momento della prenotazione',
    entryDateTitle: 'Qual è la DATA DI INGRESSO?',
    entryDateDesc: 'La ENTRANCE DATE è la data di ingresso della prenotazione. Formato: YYYY-MM-DD',
    bookingIdentifierTitle: "Qual è l'IDENTIFICATORE DELLA PRENOTAZIONE?",
    bookingIdentifierDesc:
      "L'identificatore di prenotazione è l'identificatore univoco di una determinata prenotazione nel PMS. Utilizzatelo come campo dinamico nelle vostre e-mail di pre-prenotazione e aumentate la conversione dei vostri check-in online",
    bookingIdentifierFindTitle: "Dove si trova l'IDENTIFICATORE DI PRENOTAZIONE?",
    bookingIdentifierFindDesc:
      "L'identificatore di prenotazione è un campo del vostro PMS. Il CRM dovrebbe essere in grado di ottenerlo dal PMS, nello stesso modo in cui ottiene il nome del titolare della prenotazione, la data del check-in e altri dettagli utilizzati nella prenotazione o nella comunicazione precedente alla prenotazione",
  },
  pmsUdf: {
    title: 'Gestione UDF',
    form: {
      addUdf: 'Aggiungi UDF',
      editUdf: 'Modifica UDF',
      name: 'Nome UDF',
      field: 'Campo in Civitfun',
      add: 'Aggiungi',
      edit: 'Salva modifiche',
      cancel: 'Annulla',
    },
    table: {
      title: 'UDFs',
      id: 'ID',
      name: 'Nome',
      referenceField: 'Campo di riferimento',
      edit: 'Modifica',
    },
    deleteWarning: {
      title: "Vuoi eliminare l'UDF?",
      description:
        "Questa azione non può essere annullata. Tutti i dati dell'UDF selezionato andranno persi",
      remove: 'Elimina UDF',
      cancel: 'Annulla',
    },
    removeError: "Errore durante l'eliminazione dell'UDF",
    removeSuccess: "L'UDF è stato eliminato",
    updateSuccess: "L'UDF è stato aggiornato",
    updateError: "Errore durante l'aggiornamento dell'UDF",
    createSuccess: "L'UDF è stato creato",
    createError: "Errore durante la creazione dell'UDF",
  },
};
