import { AppContext } from 'App.context.js';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator.jsx';
import Colors from 'constants/Colors.js';
import React, { useContext, useEffect, useState } from 'react';
import styles from './PoliceReportCaption.styles.js';
import { WarningOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import constants from '../../../../utils/constants';

export default function PoliceReportCaption({ platform }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const [currentPlatform, setCurrentPlatform] = useState(platform || constants.POLICE_REPORT_PLATFORMS.DEFAULT);

  useEffect(() => {
    if (platform) {
      setCurrentPlatform(platform);
    }
  }, [platform]);

  let okText, errorText, pendingText;

  switch (currentPlatform) {
    case constants.POLICE_REPORT_PLATFORMS.SES:
      okText = translate('policeReport.ses.caption.captionOk');
      errorText = translate('policeReport.ses.caption.captionError');
      pendingText = translate('policeReport.ses.caption.captionPending');
      break;
    case constants.POLICE_REPORT_PLATFORMS.ERTZANTZA:
      okText = translate('policeReport.ertz.caption.captionOk');
      errorText = translate('policeReport.ertz.caption.captionError');
      pendingText = translate('policeReport.ertz.caption.captionPending');
      break;
      case constants.POLICE_REPORT_PLATFORMS.MOSSOS:
      okText = translate('policeReport.mossos.caption.captionOk');
      errorText = translate('policeReport.mossos.caption.captionError');
      pendingText = translate('policeReport.mossos.caption.captionPending');
      break;
    default:
      okText = translate('policeReport.ses.caption.captionOk');
      errorText = translate('policeReport.ses.caption.captionError');
      pendingText = translate('policeReport.ses.caption.captionPending');
  }

  return (
    <div style={styles.container}>
      <div style={styles.caption}>
        <DotIndicator color={Colors.testOk} />
        <div>{okText}</div>
      </div>
      <div style={styles.caption}>
        <DotIndicator color={Colors.yellowPill} />
        <div>{pendingText}</div>
      </div>
      <div style={styles.caption}>
        <DotIndicator color={Colors.red} />
        <div>{errorText}</div>
      </div>
      <div style={styles.caption}>
        <Popover content={translate('policeReport.retrySendingMessage')} trigger="hover">
          <WarningOutlined
            style={{
              color: Colors.yellowButton,
              marginRight: 8,
              padding: 4,
              border: `1px solid ${Colors.yellowButton}`,
              borderRadius: 2,
              fontSize: 20,
            }}
          />
        </Popover>
      </div>
    </div>
  );
}
