import React, { useContext } from 'react';
import styles from '../PoliceReportDetail.styles.js';
import usePoliceReportDetailHook from './PoliceReportDetail.hook.js';
import { AppContext } from 'App.context.js';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import AppModal from 'Components/common/AppModal/AppModal.jsx';
import moment from 'moment';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { useLocation } from 'react-router-dom';
import Colors from 'constants/Colors.js';
import PoliceReportSideMenuItem from '../PoliceReportSideMenuItem/PoliceReportSideMenuItem.jsx';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import Loading from 'Components/common/Loader/Loading.jsx';
import FIELDS from '../../civitfun-police-report-utils/PoliceReportFields.js';

export default function PoliceReportDetail({ report }) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const location = useLocation();
  const { state, actions } = usePoliceReportDetailHook(location?.state?.report);

  return (
    <DefaultLayout howHotelSelector={false} singleBox>
      <div style={styles.wrapper}>
        <div style={styles.buttonsContainer}>
          <CustomButton
            text={translate('policeReport.back')}
            background={Colors.exportButton}
            color={Colors.white}
            onClick={() => {
              actions.onBackClicked();
            }}
          />
          {state.isLoading && <Loading size={'small'} />}
        </div>
        <div style={styles.screen}>
          <div style={{ ...styles.container, flex: 1 }}>
            <div style={styles.formSection}>
              <div style={styles.sectionTitle}>{translate('policeReport.contractData')}</div>
              {state.report?.contract && (
                <div style={styles.inputContainer}>
                  <AppInput
                    style={styles.input}
                    title={translate('policeReport.detailFields.reference')}
                    value={state.report?.contract?.reference}
                    onChange={value => actions.updateContractField(FIELDS.REFERENCE_KEY, value)}
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.REFERENCE_KEY)}
                  />
                  <AppInput
                    style={styles.input}
                    title={translate('policeReport.detailFields.date')}
                    value={
                      state.report?.contract?.[FIELDS.CONTRACTDATE_KEY]
                        ? moment(state.report?.contract?.[FIELDS.CONTRACTDATE_KEY])
                        : null
                    }
                    onChange={value => actions.updateContractField(FIELDS.CONTRACTDATE_KEY, value)}
                    type={AppInputTypes.DATE}
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.CONTRACTDATE_KEY)}
                  />
                  <AppInput
                    style={styles.input}
                    title={translate('policeReport.detailFields.entrance')}
                    value={
                      state.report?.contract?.[FIELDS.ENTRANCE_KEY]
                        ? moment(state.report?.contract?.[FIELDS.ENTRANCE_KEY])
                        : null
                    }
                    onChange={value => actions.updateContractField(FIELDS.ENTRANCE_KEY, value)}
                    type={AppInputTypes.DATE}
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.ENTRANCE_KEY)}
                  />
                  <AppInput
                    style={styles.input}
                    title={translate('policeReport.detailFields.departure')}
                    value={
                      state.report?.contract?.[FIELDS.DEPARTURE_KEY]
                        ? moment(state.report?.contract?.[FIELDS.DEPARTURE_KEY])
                        : null
                    }
                    onChange={value => actions.updateContractField(FIELDS.DEPARTURE_KEY, value)}
                    type={AppInputTypes.DATE}
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.DEPARTURE_KEY)}
                  />
                  <AppInput
                    style={styles.input}
                    title={translate('policeReport.detailFields.guests')}
                    value={state.report?.contract?.[FIELDS.PAXES_KEY]}
                    onChange={value => actions.updateContractField(FIELDS.PAXES_KEY, value)}
                    type={AppInputTypes.NUMERIC}
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.PAXES_KEY)}
                  />
                </div>
              )}
            </div>

            <div style={styles.separator} />
            {state.report?.contract && (
              <div style={styles.formSection}>
                <div style={styles.sectionTitle}>{translate('policeReport.paymentData')}</div>
                <div style={styles.inputContainer}>
                  <AppSelector
                    style={styles.input}
                    title={translate('policeReport.detailFields.paymentType')}
                    value={state.report?.contract?.paymentType || null}
                    options={actions.getPaymentTypeOptions()}
                    onOptionSelected={option =>
                      actions.updateContractField(FIELDS.PAYMENTTYPE_KEY, option)
                    }
                    disabled={state.disableForm}
                    hasError={state.errorFields?.contract?.includes(FIELDS.PAYMENTTYPE_KEY)}
                  />
                </div>
              </div>
            )}

            {state.report?.guests?.map((guest, index) => {
              {actions.isDocTypeEmpty(guest?.documentType, index); actions.disableFieldOnDocTypeEmpty()}
              return <>
                <div key={guest?.id || index} style={styles.separator} />
                  <div style={styles.formSection}>
                    <div style={styles.sectionTitle}>{`${translate('policeReport.guestData')} ${
                      index + 1
                    }`}</div>
                    <div style={styles.inputContainer}>
                      <AppSelector
                        style={styles.input}
                        title={translate('policeReport.detailFields.role')}
                        value={index == 0 ? 'TI' : guest?.roleType || 'VI'}
                        options={actions.getRoleTypeOptions()}
                        onOptionSelected={option =>
                          actions.updateGuestField(index, FIELDS.ROLETYPE_KEY, option)
                        }
                        isLoading={state.isLoading}
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.ROLETYPE_KEY)}
                      />

                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.name')}
                        value={guest?.name}
                        onChange={value => actions.updateGuestField(index, FIELDS.NAME_KEY, value)}
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.NAME_KEY)}
                      />

                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.surname')}
                        value={guest?.surname}
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.SURNAME_KEY, value)
                        }
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.SURNAME_KEY)}
                      />

                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.secondSurname')}
                        value={guest?.secondSurname}
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.SECONDSURNAME_KEY, value)
                        }
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(
                          FIELDS.SECONDSURNAME_KEY
                        )}
                      />
                    </div>
                    <div style={styles.inputContainer}>
                      <AppSelector
                        style={styles.input}
                        title={translate('policeReport.detailFields.documentType')}
                        value={guest?.documentType} // empty = reset
                        options={actions.getDocumentTypeOptions()}
                        onOptionSelected={option =>
                          actions.updateGuestField(index, FIELDS.DOCUMENTTYPE_KEY, option)
                        }
                        isLoading={state.isLoading}
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(
                          FIELDS.DOCUMENTTYPE_KEY
                        )}
                      />
                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.documentNumber')}
                        value={
                          actions.disableFieldOnDocTypeEmpty(guest?.documentType) 
                            ? "" 
                            : guest?.documentNumber
                        }
                        onChange={value => actions.updateGuestField(index, FIELDS.DOCUMENTNUMBER_KEY, value)}
                        disabled={actions.disableFieldOnDocTypeEmpty(guest?.documentType)}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.DOCUMENTNUMBER_KEY)}
                      />
                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.documentSupport')}
                        value={
                          actions.disableSupportNumber(guest.documentType)
                            ? "" 
                            : guest?.document_support_number ?? guest?.supportDocument ??  ""
                        }
                        onChange={value => actions.updateGuestField(index, FIELDS.DOCUMENTSUPPORT_KEY, value)}
                        disabled={
                          actions.disableSupportNumber(guest.documentType) || 
                          actions.disableFieldOnDocTypeEmpty(guest?.documentType)
                        }
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.DOCUMENTSUPPORT_KEY)}
                      />


                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.birthDate')}
                        value={
                          guest[FIELDS.BIRTHDATE_KEY] ? moment(guest[FIELDS.BIRTHDATE_KEY]) : null
                        }
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.BIRTHDATE_KEY, value)
                        }
                        type={AppInputTypes.DATE}
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.BIRTHDATE_KEY)}
                        disabledDate={current => current && current > new Date()}
                      />
                    </div>
                    <div style={styles.inputContainer}>
                      <AppSelector
                        style={styles.input}
                        title={translate('policeReport.detailFields.nationality')}
                        value={guest?.nationality || null}
                        options={actions.getCountryOptions()}
                        onOptionSelected={option =>
                          actions.updateGuestField(index, FIELDS.NATIONALITY_KEY, option)
                        }
                        disabled={state.disableForm}
                        searchable
                      />
                      <AppSelector
                        style={styles.input}
                        title={translate('policeReport.detailFields.contactType')}
                        value={guest?.contactType}
                        options={actions.getContactTypeOptions()}
                        onOptionSelected={option =>
                          actions.updateGuestField(index, FIELDS.CONTACTTYPE_KEY, option)
                        }
                        isLoading={state.isLoading}
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(
                          FIELDS.CONTACTTYPE_KEY
                        )}
                      />
                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.contact')}
                        value={guest?.contactValue}
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.CONTACTVALUE_KEY, value)
                        }
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(
                          FIELDS.CONTACTVALUE_KEY
                        )}
                      />
                      {actions.showFamilyRelationship(guest[FIELDS.BIRTHDATE_KEY]) && (
                        <AppSelector
                          style={styles.input}
                          title={translate('policeReport.detailFields.familyRelationship')}
                          value={guest[FIELDS.FAMILYRELATIONSHIP_KEY] || null}
                          options={actions.getFamilyRelationshipOptions()}
                          onOptionSelected={option =>
                            actions.updateGuestField(index, FIELDS.FAMILYRELATIONSHIP_KEY, option)
                          }
                          isLoading={state.isLoading}
                          disabled={state.disableForm}
                          hasError={state.errorFields?.guests[index]?.includes(
                            FIELDS.FAMILYRELATIONSHIP_KEY
                          )}
                        />
                      )}
                    </div>
                    <div style={styles.inputContainer}>
                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.address')}
                        value={guest?.address}
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.ADDRESS_KEY, value)
                        }
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.ADDRESS_KEY)}
                      />
                      {guest?.country === 'ESP' ? (
                        <AppSelector
                          style={styles.input}
                          title={translate('policeReport.detailFields.municipality')}
                          value={guest?.municipalityCode?.toString() || null}
                          options={actions.getMunicipalityOptions()}
                          onOptionSelected={option =>
                            actions.updateGuestField(index, FIELDS.MUNICIPALITY_KEY, option)
                          }
                          disabled={state.disableForm}
                          searchable
                          hasError={state.errorFields?.guests[index]?.includes(
                            FIELDS.MUNICIPALITY_KEY
                          )}
                        />
                      ) : (
                        <AppInput
                          style={styles.input}
                          title={translate('policeReport.detailFields.municipalityName')}
                          value={guest?.municipality}
                          onChange={value =>
                            actions.updateGuestField(index, FIELDS.MUNICIPALITYNAME_KEY, value)
                          }
                          disabled={state.disableForm}
                          hasError={state.errorFields?.guests[index]?.includes(
                            FIELDS.MUNICIPALITYNAME_KEY
                          )}
                        />
                      )}
                      <AppInput
                        style={styles.input}
                        title={translate('policeReport.detailFields.zipCode')}
                        value={guest?.zipCode || guest?.postalCode}
                        onChange={value =>
                          actions.updateGuestField(index, FIELDS.ZIPCODE_KEY, value)
                        }
                        disabled={state.disableForm}
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.ZIPCODE_KEY)}
                      />
                      <AppSelector
                        style={styles.input}
                        title={translate('policeReport.detailFields.country')}
                        value={guest?.country || null}
                        options={actions.getCountryOptions()}
                        onOptionSelected={option =>
                          actions.updateGuestField(index, FIELDS.COUNTRY_KEY, option)
                        }
                        disabled={state.disableForm}
                        searchable
                        hasError={state.errorFields?.guests[index]?.includes(FIELDS.COUNTRY_KEY)}
                      />
                    </div>
                  </div>
                  <span/>
                  {state.report?.guests.length > 1 && ( // dont show the button when there is only 1 guest
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "4%" }}>
                      <AppButton
                        disabled={appState.role === 'guest'}
                        action={() => actions.onDeleteGuestClicked(guest)}
                        redButton={true}
                        text={translate('policeReport.sideItem.delete.button')}
                      />
                    </div>
                  )}

                </>
                
              
            })}

            <AppModal
              show={state.showSendWarning}
              title={translate('policeReport.showSendWarningTitle')}
              description={translate('policeReport.showSendWarning')}
              okText={translate('Ok')}
              okAction={actions.sendReport}
              cancelAction={() => actions.setShowSendWarning(false)}
              closable={false}
            />

            <AppModal // booking
              show={state.showBookingDeleteWarning}
              title={translate('policeReport.sideItem.delete.warning.title')}
              description={translate('policeReport.sideItem.delete.warning.description')}
              okText={translate('Ok')}
              okAction={actions.sendDeleteBooking}
              cancelAction={() => actions.setShowBookingDeleteWarning(false)}
              closable={false}
            />
             <AppModal // guest
              show={state.showGuestDeleteWarning}
              title={translate('policeReport.sideItem.delete.warning.title')}
              description={translate('policeReport.sideItem.delete.warning.description')}
              okText={translate('Ok')}
              okAction={actions.sendDeleteGuest}
              cancelAction={() => actions.setShowGuestDeleteWarning(false)}
              closable={false}
            />
          </div>

          <div style={styles.sideMenu}>
            <PoliceReportSideMenuItem
              title={translate('policeReport.sideItem.booking.title')}
              indicatorColor={Colors.greenPill}
              header={translate('policeReport.sideItem.booking.header')}
              number={`${state.checkinCount}/${state.checkinCount}`}
              desc={translate('policeReport.sideItem.booking.desc')}
            />
            {state.disableForm ? null : (
              <PoliceReportSideMenuItem
                icon={<SendOutlined style={{ fontSize: 30, marginBottom: 8 }} />}
                header={translate('policeReport.sideItem.send.header')}
                desc={translate('policeReport.sideItem.send.desc')}
                action={actions.sendReport}
              >
                <AppButton
                  disabled={appState.role === 'guest'}
                  action={() => actions.onSendClicked()}
                  text={translate('policeReport.sendData')}
                />
              </PoliceReportSideMenuItem>
            )}
             <PoliceReportSideMenuItem

                icon={<DeleteOutlined  style={{ fontSize: 30, marginBottom: 8}} />}
                header={translate('policeReport.sideItem.delete.title')}
                desc={translate('policeReport.sideItem.delete.desc')}
              >
                <AppButton
                  disabled={appState.role === 'guest'}
                  action={() => actions.onDeleteBookingClicked()}
                  text={translate('policeReport.sideItem.delete.button')}
                  redButton={true}

                />
              </PoliceReportSideMenuItem>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
