import { EyeOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal } from 'antd';
import React, { useContext } from 'react';
import BaseTable from '../../common/BaseTable/BaseTable';
import CustomButton from '../../common/CustomButton/CustomButton';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import useCheckInListHook from './CheckInList.hook';
import styles from './CheckInList.styles';
import CheckInDetail from './CheckInDetail/CheckInDetail';
import Colors from '../../../constants/Colors';
import IHClientTag from '../../common/IHClientTag/IHClientTag';
import { AppContext } from '../../../App.context';
import { getFormattedDate } from 'utils/DateUtils';
import RequestSuscription from 'Components/common/RequestSuscription/RequestSuscription.jsx';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator';
import usePMSUDFConfigHook from '../PMS/PMSUDFConfig/PMSUDFConfig.hook';

export default function CheckInList() {
  const { state, actions } = useCheckInListHook();
  const { state: pmsUdfState } = usePMSUDFConfigHook();
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);

  const allowedUserIds = [ 139, 816, 915, 2554, 2715, 2716, 2723, 3095, 3892,
    3898, 4633, 4945, 5086, 5410, 5677, 5757, 5992, 6279, 6281, 6282, 6283,
    6746, 7304, 7323, 7324, 7325, 7326, 7340, 7894, 7959, 8090, 8193,
  ];
  const canViewState = allowedUserIds.includes(appState.userId);

  const stateRow = {
    title: translate('checkInList.stateCol'),
    dataIndex: 'status',
    key: 'status',
    sorter: false,
  
    render: (_, object) => {
      if (object.checkinDiscrepancy === null || object.checkinDiscrepancy?.created_at <= '2025-03-22 00:00:00') {
        return null;
      }
      
      const fieldsInDiscrepancies = object.checkinDiscrepancy?.discrepancies?.map(discrepancy => discrepancy.field);
      const udfFields = pmsUdfState.hotelUdfs.map(udf => udf.validate);
      
      let status;
      if (fieldsInDiscrepancies === undefined) {
        status = 'pending';
      } else if (fieldsInDiscrepancies.length > 0) {
        const supportedFields = state.supportedFields.map(field => field.civitfun);
        const hasRelevantDiscrepancies = fieldsInDiscrepancies.some(field => 
          supportedFields.includes(field) || udfFields.includes(field)
        );
        status = hasRelevantDiscrepancies ? 'error' : 'ok';
      } else {
        status = 'ok';
      }
  
      const statusText =
        status === 'error' ? actions.parseError(fieldsInDiscrepancies, udfFields, object) || translate(`checkInList.status.error`)
          : status === 'pending' ? translate(`checkInList.status.pending`)
          : translate(`checkInList.status.ok`);
  
      return (
        <div style={styles.statusContainer}>
          <DotIndicator color={actions.getColorForStatus(status)} />
          <div>{statusText}</div>
        </div>
      );
    },
  };

  const columns = [
    {
      title: translate('checkInList.locatorCol'),
      dataIndex: ['booking', 'code'],
      key: 'booking.code',
      searchable: true,
      sorter: false,
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.booking.code}</div>;
      },
    },
    {
      title: translate('checkInList.typeCol'),
      dataIndex: 'guestType',
      render: (val, id) => <GuestTypeTag text={val} />,
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.nameCol'),
      dataIndex: ['guest', 'name'],
      key: 'guest.name',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.surnameCol'),
      dataIndex: ['guest', 'surname'],
      key: 'guest.surnname',
      searchable: false,
      sorter: false,
    },
    {
      title: 'Hotel',
      dataIndex: 'hotel',
      key: 'hotel',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('checkInList.checkinDate'),
      dataIndex: ['sent_to_pms'],
      key: 'sent_to_pms',
      searchable: false,
      sorter: true,
      render: text => {
        return <div>{getFormattedDate(text)}</div>;
      },
    },
    {
      title: translate('checkInList.checkinCol'),
      dataIndex: ['booking', 'entrance'],
      key: 'booking.entrance',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkInList.checkoutCol'),
      dataIndex: ['booking', 'departure'],
      key: 'booking.departure',
      searchable: false,
      sorter: true,
    },
    {
      title: translate('checkInList.channelCol'),
      dataIndex: 'clientId',
      key: 'clientId',
      align: 'center',
      width: '5%',
      render: text => {
        return <IHClientTag ihClientId={text} />;
      },
    },
    {
      title: translate('checkInList.actions'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (text, object) => {
        return <Button type="secondary" icon={<EyeOutlined />} />;
      },
    },
  ];

  if (canViewState) {
    columns.unshift(stateRow);
  }

  const { RangePicker } = DatePicker;

  return (
    <DefaultLayout singleBox title={translate('checkInList.title')} enableAllHotels={true}>
      { state.subscription ? (
        <>
          <div style={styles.mainContainer}>
            <div style={styles.header}>
              {canViewState && (
                <>
                  <div style={styles.headerState}>
                    <DotIndicator color={Colors.testOk} />
                    <div>{translate('checkInList.stateOk')}</div>
                  </div>
                  <div style={styles.headerState}>
                    <DotIndicator color={Colors.yellowPill} />
                    <div>{translate('checkInList.statePending')}</div>
                  </div>
                  <div style={styles.headerState}>
                    <DotIndicator color={Colors.red} />
                    <div>{translate('checkInList.stateError')}</div>
                  </div>
                </>
              )}
              {/* <div style={styles.title}>{translate('checkInList.title')}</div> */}
              <div style={styles.buttonsContainer}>
                <RangePicker
                  defaultValue={state.datesRange}
                  onChange={actions.onDateChange}
                  disabled={state.loading}
                />
                <CustomButton
                  text={translate('checkInList.export')}
                  background={Colors.exportButton}
                  color={'white'}
                  disabled={appState.role === 'guest'}
                  onClick={() => {
                    actions.export();
                  }}
                ></CustomButton>
              </div>
            </div>
          </div>

          <div style={styles.tableContainer}>
            <div style={styles.table}>
              <BaseTable
                data={state.dataSource}
                columns={columns}
                loading={state.loading}
                onChange={filters => actions.onChange(filters)}
                onRowClicked={actions.onRowClicked}
              />
            </div>
          </div>
          <Modal
            visible={state.checkinId !== null}
            centered
            width={'80%'}
            onCancel={actions.resetCheckinDetail}
            footer={null}
            style={{ marginTop: '24px' }}
            destroyOnClose={true}
          >
            <CheckInDetail checkinId={state.checkinId} />
          </Modal>
        </>
      ) : (
        <RequestSuscription suscription='checkin' />
      )}
    </DefaultLayout>
  );
}

function GuestTypeTag(text) {
  var literal;
  switch (text.text) {
    case 'adult':
    default:
      literal = 'Adulto';
      break;
    case 'children':
      literal = 'Niño';
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        color: '#3e3e3e',
      }}
    >
      {literal}
    </div>
  );
}
