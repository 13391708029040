import './UnavailableBadge.css';
import { AppContext } from '../../../../../App.context';
import React, { useContext } from 'react';
import hasUdfFields from '../../../../../utils/hasUdfFields';

const fieldsName = {
  documentSupportNumber: 'documentSupportNum',
  arrivalFlight: 'arrivalFlightNumber',
  departureFlight: 'departureFlightNumber',
};

export default function UnavailableBadge({ pmsModuleName, fieldName }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  let hasUdf = hasUdfFields(pmsModuleName);

  const mappedFieldName = fieldsName[fieldName] || fieldName;

  const isUploadDocument = fieldName === 'uploadDocument1' || fieldName === 'uploadDocument2';

  return (
    <div
      className={`
        ${hasUdf && !isUploadDocument ? 'badgeMapper-container' : 'badge-container'} 
      `}
    >
      <span
        className={`
          ${hasUdf && !isUploadDocument ? 'badgeMapper-dot' : 'badge-dot'} 
        `}
      ></span>
      {isUploadDocument ? (
        translate('guestTable.unavailableInPMS')
      ) : hasUdf ? (
        ['Cloudbeds', 'Cloudbeds_new'].includes(pmsModuleName)
        ? `${translate('guestTable.requiresMappingInPMSCloudbeds')} ${mappedFieldName}`
        : ['Opera', 'Ohip'].includes(pmsModuleName)
        ? translate('guestTable.requiresMappingInPMSOpera')
        : null
      ) : (
        translate('guestTable.unavailableInPMS')
      )}
    </div>
  );
}
