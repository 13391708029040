import { AppContext } from 'App.context';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { notification } from 'antd';
import storage from '../../../../utils/storage';

export default function useAccountOwnerDetailHook() {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const selectedHotelData = storage.get('selectedHotel');
  const hotelId = JSON.parse(selectedHotelData)?.id;

  const [state, setState] = useState({
    chainName: null,
    name: null,
    email: null,
    phone: null,
    isLoading: false,
  });

  useEffect(() => {
    actions.loadOwnerData();
  }, []);

  const actions = {
    loadOwnerData: () => {
      updateState({ isLoading: true });
      API.userRequest({ userId: appState.ownerId })
        .then(data => {
          const formattedPhone = data?.phone?.length == 9 ? `34${data?.phone}` : data?.phone;

          updateState({
            isLoading: false,
            name: data?.name,
            email: data?.email,
            phone: formattedPhone,
          });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    validateFormAndUpdateUser: () => {
      if (!state.phone) {
        notification.error({
          message: translate('user.emptyPhone'),
        });
      } else {
        actions.updateUserData();
      }
    },

    updateUserData: () => {
      updateState({ isLoading: true });
      API.updateOwnerRequest({ chainName: state.chainName, userId: appState.ownerId, name: state.name, phone: state.phone, hotelId: hotelId })
        .then(_ => {
          updateState({ isLoading: false });
          notification.success({
            message: translate('user.updateSuccess'),
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.success({
            message: translate('update.updateError'),
          });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
