import React, { useContext, useImperativeHandle } from 'react';
import styles from './DynamicForms.styles.js';
import useDynamicFormsHook from './DynamicForms.hook.js';
import { Table } from 'antd';
import './style.css';
import DynamicFormsRowType from './DynamicFormsRowType';
import DynamicFormsRow from './DynamicFormsRow/DynamicFormsRow.jsx';
import { AppContext } from 'App.context.js';
import { Popover } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import Colors from 'constants/Colors';
import useGuestHook from '../../PMS/PMSSelector/Guest/hooks/Guest.hook';
import UnavailableBadge from '../../CheckIn/GuestTable/UnavailableInPms/UnavailableInPms';
import { useSupportedFields } from '../../CheckIn/GuestTable/useSupportedFields.js';
import usePMSUDFConfigHook from '../../PMS/PMSUDFConfig/PMSUDFConfig.hook';

const { HEADER, SECTION, FIELD, FIELD_GROUP } = DynamicFormsRowType;

export default function DynamicForms({
  formRef,
  isLoading,
  form,
  onSave,
  selectedLanguage,
  languages,
}) {
  const {
    appState,
    appActions: { translate },
  } = useContext(AppContext);
  const { snakeToCamel } = useGuestHook();
  const { state, actions } = useDynamicFormsHook(form, selectedLanguage);

  useImperativeHandle(formRef, () => ({ saveClicked }));

  const saveClicked = applyToAllHotels => {
    onSave(state.form, applyToAllHotels);
  };

  const { supportedFields, pmsModuleName } = useSupportedFields();
  const { state: pmsUdfState } = usePMSUDFConfigHook();

  const columns = [
    {
      title: translate('guestTable.fieldCol'),
      dataIndex: 'rowType',
      fixed: 'left',
      width: '14%',
      render: (rowType, item) => {
        switch (rowType) {
          case FIELD:
            if (item.field === 'family_relationship' || item.field === 'document_support_number') {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{actions.getFieldLiteral(item.literals)?.value}</div>
                  <div style={{ marginLeft: 'auto', paddingLeft: '10px' }}>
                    <Popover content={translate(item.field)} trigger="hover">
                      <WarningOutlined
                        style={{
                          color: Colors.yellowButton,
                          marginRight: 8,
                          padding: 4,
                          border: `1px solid ${Colors.yellowButton}`,
                          borderRadius: 2,
                          fontSize: 14,
                        }}
                      />
                    </Popover>
                  </div>
                </div>
              );
            }
            return item.field.includes('uploadDocument')
              ? actions.getEditableInput({
                  item: actions.getFieldLiteral(item.literals),
                  onValueChanged: actions.onFieldTextChanged,
                  supportedLanguages: languages,
                  allLiterals: item.literals,
                  field: item.field,
                })
              : actions.getFieldLiteral(item.literals)?.value;
          case FIELD_GROUP:
          case SECTION:
            return <div style={{ ...styles.section }}>{translate(`guestTable.fieldCol`)}</div>;
          case HEADER:
          default:
            return null;
        }
      },
    },
    {
      title: translate('guestTable.fieldCol'),
      dataIndex: 'rowType',
      fixed: 'left',
      width: '22%',
      render: (rowType, item) => {
        switch (rowType) {
          case FIELD:
            const fieldName = snakeToCamel(item.field);
            let pmsName = '';

            if (supportedFields.length == 0) return;

            supportedFields.forEach(supportedField => {
              if (fieldName.trim() === supportedField.civitfun.trim()) {
                pmsName = supportedField.pms;
              }
            });

            // check UDF values
            if (pmsUdfState) {
              pmsUdfState.hotelUdfs.forEach(udf => {
                if (snakeToCamel(udf.validate) == fieldName) {
                  if (pmsName) pmsName += ' + ' + udf.name;
                  else pmsName = udf.name;
                }
              });
            }

            return (
              pmsName || <UnavailableBadge pmsModuleName={pmsModuleName} fieldName={fieldName} />
            );
          case FIELD_GROUP:
          case SECTION:
            return (
              <div
                style={{
                  ...styles.section,
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                  borderRadius: '0',
                }}
              >
                {translate(`guestTable.fieldColPMS`)}
              </div>
            );
          case HEADER:
          default:
            return null;
        }
      },
    },
    {
      dataIndex: 'rowType',
      render: (rowType, item) => {
        return (
          <div style={styles.valueContainer}>
            <DynamicFormsRow
              rowType={rowType}
              item={item}
              setFieldValue={actions.setFieldValue}
              pmsModuleName={pmsModuleName}
              pmsUdfState={pmsUdfState}
              supportedFields={supportedFields}
            />
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className={'dynamicForm'}>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={state.form?.fields}
        isLoading={isLoading || state.isLoading}
        pagination={false}
        scroll={{
          x: 10,
        }}
      />
    </div>
  );
}
