import { Form, notification } from 'antd';
import { camelToUnderscore } from 'utils/TextUtils';
import * as Translations from 'modules/localization/Translations';
import SupportedLanguages from 'modules/localization/SupportedLanguages';

const { AppContext } = require('App.context');
const { useContext, useState, useEffect } = require('react');
const { ApiContext } = require('utils/networking/Api.context');
const { FORM_MODES } = require('./PMSUDF.constants');

const extraFields = [
  'loyalty_program',
  'data_privacy_policy',
  'data_commercial_use',
  'allow_telephone_contact',
];

const usePMSUDFConfigHook = () => {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [form] = Form.useForm();

  useEffect(() => {
    actions.initialize();
  }, []);

  const formInitialState = {
    udfId: null,
    udfName: '',
    udfField: '',
  };

  const [state, setState] = useState({
    hotelFields: [],
    hotelUdfs: [],
    isLoadingFields: false,
    isLoadingUDFs: false,
    isLoadingForm: false,
    formMode: FORM_MODES.CREATE,
    ...formInitialState,
    form,
    showDeleteWarning: false,
  });

  const hotelId = getSelectedHotel()?.id;

  const actions = {
    initialize: async () => {
      await actions.loadHotelFields();
      actions.loadHotelUdfs();
    },
    getHotelUdfField: validate =>
      state.hotelFields
        .find(
          hotelField =>
            camelToUnderscore(hotelField.name).toUpperCase() === validate?.toUpperCase().trim()
        )
        ?.formTexts?.find(({ lang }) => lang === appState.currentLanguage.id)?.value ?? validate,
    loadHotelUdfs: async () => {
      updateState({ isLoadingUDFs: true });
      const hotelUdfs = await API.pmsGetUdfConfigRequest({ hotelId });
      updateState({ hotelUdfs, isLoadingUDFs: false });
    },
    loadHotelFields: async () => {
      updateState({ isLoadingFields: true });
      let hotelFields = (await API.guestFormsRequest(hotelId))?.hotelFields;
      if (!Array.isArray(hotelFields)) {
        if (!Object.keys(hotelFields).length) {
          hotelFields = [];
        } else hotelFields = Object.entries(hotelFields)[0][1];
      }
      for (const extraField of extraFields) {
        hotelFields = hotelFields.filter(({ name }) => name !== extraField);
        hotelFields.push({
          name: extraField,
          formTexts: SupportedLanguages.map(supportedLanguage => ({
            lang: supportedLanguage.id,
            value:
              extraField === 'allow_telephone_contact'
                ? Translations.translate(
                    supportedLanguage.translations,
                    `legalDocuments.tags.guest_${extraField}`
                  )
                : Translations.translate(
                    supportedLanguage.translations,
                    `legalDocuments.tags.${extraField}`
                  ),
          })),
        });
      }
      updateState({ hotelFields, isLoadingFields: false });
    },
    onInputChange: (key, value) => {
      updateState({ [key]: value });
    },
    onFieldSelected: option => {
      const selectedField = state.hotelFields[option];
      updateState({ udfField: selectedField?.name });
    },
    onButtonClicked: () => {
      const { udfField, udfName } = state;
      if (udfField.trim().length && udfName.trim().length) {
        const buttonActions = {
          [FORM_MODES.CREATE]: actions.onCreateUdf,
          [FORM_MODES.EDIT]: actions.onUpdateUdf,
        };
        buttonActions[state.formMode]();
      }
    },
    getFieldValue: key => state[key],
    onCreateUdf: async () => {
      updateState({ isLoadingForm: true });
      try {
        const createdUdf = await API.pmsCreateUdfConfigRequest({
          hotelId,
          field: state.udfField,
          name: state.udfName,
        });
        updateState({
          isLoadingForm: false,
          hotelUdfs: [...state.hotelUdfs, createdUdf],
        });
        actions.clearForm();
        notification.success({
          message: translate('pmsUdf.createSuccess'),
        });
      } catch (error) {
        notification.error({
          message: translate('pmsUdf.createError'),
        });
      }
    },
    onUpdateUdf: async () => {
      updateState({ isLoadingForm: true });
      try {
        const { udfField: field, udfName: name, udfId } = state;
        await API.pmsUpdateUdfConfigRequest({
          hotelId,
          field,
          name,
          udfId,
        });
        const updatedHotelUdfs = state.hotelUdfs.map(hotelUdf => {
          if (hotelUdf.id === state.udfId) {
            return { ...hotelUdf, name, validate: field };
          }
          return hotelUdf;
        });
        updateState({
          isLoadingForm: false,
          hotelUdfs: updatedHotelUdfs,
          formMode: FORM_MODES.CREATE,
        });
        actions.clearForm();
        notification.success({
          message: translate('pmsUdf.updateSuccess'),
        });
      } catch (error) {
        updateState({ isLoadingForm: false });
        notification.error({
          message: translate('pmsUdf.updateError'),
        });
      }
    },
    clearForm: () => {
      updateState({ ...formInitialState });
      form.setFieldsValue({
        udfName: '',
        udfField: '',
      });
    },
    onClickDelete: id => {
      updateState({ udfId: id, showDeleteWarning: true });
    },
    onConfirmDelete: async () => {
      try {
        await API.pmsDeleteUdfConfigRequest({ hotelId, udfId: state.udfId });
        const hotelUdfs = state.hotelUdfs.filter(({ id }) => id !== state.udfId);
        actions.clearForm();
        updateState({
          udfId: null,
          showDeleteWarning: false,
          hotelUdfs,
          formMode: FORM_MODES.CREATE,
        });
        notification.success({
          message: translate('pmsUdf.removeSuccess'),
        });
      } catch (error) {
        updateState({ udfId: null, showDeleteWarning: false });
        notification.error({
          message: translate('pmsUdf.removeError'),
        });
      }
    },
    onCancelModal: () => {
      actions.clearForm();
      updateState({ showDeleteWarning: false });
    },
    onCancelEdit: () => {
      actions.clearForm();
      updateState({ udfId: null, udfField: '', udfName: '', formMode: FORM_MODES.CREATE });
    },
    onClickEdit: id => {
      const { name: udfName, validate: udfField } = state.hotelUdfs.find(
        ({ id: udfId }) => udfId === id
      );
      form.setFieldsValue({
        udfName,
        udfField: actions.getHotelUdfField(udfField),
      });
      updateState({ udfId: id, formMode: FORM_MODES.EDIT, udfName, udfField });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
};

export default usePMSUDFConfigHook;
