import axios from 'axios';
import apiConfig from '../../api.config';

export default function getDownloadFiles({ hotelId, booking = null, date }) {
  // Returns a .ZIP
  const {
    host,
    URIs: { policeReportDownloadFiles }, 
  } = apiConfig;

  const uri = `${host}${policeReportDownloadFiles}`;

  return axios
    .post(uri, JSON.stringify({ hotelId, booking, date }), {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => {
      return res.data;
    });
}
