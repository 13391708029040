import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { AppContext } from 'App.context';

export default function useCheckInGuestDetailHook(discrepanciesData) {
  const { API } = useContext(ApiContext);
  const {
    appActions: { getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    discrepancies: {},
    supportedFields: [],
  });

  useEffect(() => {
    actions.processDiscrepancies();
  }, [discrepanciesData]);

  useEffect(() => {
    actions.fetchPmsConfig();
  }, [getSelectedHotel()?.id]);

  const actions = {
    processDiscrepancies: () => {
      const processedDiscrepancies =
        discrepanciesData?.reduce((acc, item) => {
          const snakeCaseField = camelToSnake(item.field);
          const camelCaseField = snakeToCamel(item.field);

          acc[snakeCaseField] = {
            hasDiscrepancy: true,
            pmsValue: item.pmsValue || 'null',
          };
          acc[camelCaseField] = {
            hasDiscrepancy: true,
            pmsValue: item.pmsValue || 'null',
          };

          return acc;
        }, {}) || {};

      updateState({ discrepancies: processedDiscrepancies });
    },

    fetchPmsConfig: async () => {
      try {
        const config = await API.pmsConfigDynamicFormByHotelIdRequest({
          hotelId: getSelectedHotel()?.id,
        });

        const processedFields = config.config_dynamic_form.supportedFields.flatMap(
          (field) => [
            { civitfun: camelToSnake(field.civitfun) },
            { civitfun: snakeToCamel(field.civitfun) },
          ]
        );

        updateState({ supportedFields: processedFields });
      } catch (error) {
        console.error('Error loading PMS config:', error);
      }
    },
  };
  
  function camelToSnake(str) {
    return str.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();
  }
  
  function snakeToCamel(str) {
    return str.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase());
  }

  function updateState(object) {
    setState((previousState) => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
