import axios from 'axios';
import apiConfig from '../../api.config';

var CancelToken = axios.CancelToken;
var cancel;

export default function policeReportSendDelete(data) {
  const {
    host,
    URIs: { sendPoliceReportDelete },
  } = apiConfig;

  const uri = `${host}${sendPoliceReportDelete}`;

  if (cancel) cancel();

  return axios
    .delete(uri,{data}, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(res => {
      return res.data;
    });
}
