import Colors from 'constants/Colors';

export default {
  credentialsContainer: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    marginTop: 16,
    gap: 16,
  },
  inputContainer: {
    borderRadius: 6,
    padding: 4,
    flex: 1,
  },
  header: {
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.darkText,
  },
};
