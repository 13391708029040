import axios from 'axios';
import apiConfig from '../api.config';

export default function policeReportCheckSFTPConnection(data) {
  const {
    host,
    URIs: { policeReportCheckSFTPConnection },
  } = apiConfig;

  const uri = `${host}${policeReportCheckSFTPConnection}`;

  return axios
    .post(uri, data)
    .then(res => {
      return res.data;
    });
}
