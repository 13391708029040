export default {
  credentialsContainer: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
    marginTop: 16,
    gap: 16,
  },
  port: {
    maxWidth: 100,
  },
  inputContainer: {
    flex: 1,
  },
  header: {
    marginBottom: 8,
    fontWeight: 'bold',
  },
  validateSFTPWithoutErrors: {
    display: 'flex',
    gap: 16,
    marginTop: 16,
    marginBottom: 16,
    color: 'green',
  },
};
