import axios from 'axios';
import apiConfig from '../../api.config';

var CancelToken = axios.CancelToken;
var cancel;

export default function policeReportSendAllErrors(data) {
  const {
    host,
    URIs: { sendPoliceReportAllErrors },
  } = apiConfig;

  const uri = `${host}${sendPoliceReportAllErrors}`;

  if (cancel) cancel();

  return axios
    .post(uri,data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(res => {
      return res.data;
    });
}
