import { useState, useEffect, useContext } from 'react';
import useApi from '../../../../utils/networking/Api.hook';
import useGuestHook from '../../PMS/PMSSelector/Guest/hooks/Guest.hook';
import { AppContext } from '../../../../App.context';

export const useSupportedFields = () => {
  const [supportedFields, setSupportedFields] = useState([]);
  const [pmsModuleName, setPmsModuleName] = useState();
  const { appState, appActions } = useContext(AppContext);
  const { API } = useApi({ appState, appActions });
  const { snakeToCamel } = useGuestHook();
  const hotelId = appActions.getSelectedHotel().id;

  useEffect(() => {
    const fetchPmsConfig = async () => {
      try {
        const config = await API.pmsConfigDynamicFormByHotelIdRequest({
          hotelId,
        });
        config.config_dynamic_form.supportedFields.map(
          supportedField => (supportedField.civitfun = snakeToCamel(supportedField.civitfun))
        );
        setPmsModuleName(config.name);
        setSupportedFields(config.config_dynamic_form.supportedFields);
      } catch (error) {
        console.error('Error loading PMS config:', error);
      }
    };
    fetchPmsConfig();
  }, [hotelId]);

  return { supportedFields, pmsModuleName };
};
