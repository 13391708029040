import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function usePoliceReportSESConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    isLoading: false,
    sesUser: null,
    sesPass: null,
    lessorCode: null,
    establishmentCode: null,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    platform: null,
    isConfigLoaded: false,
    
  });

  useEffect(() => {
    if(state.isConfigLoaded){
    actions.loadPoliceReportConfig();
    }
  }, [appState.selectedHotel]);

  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          updateState({
            isLoading: false,
            sesUser: res?.sesUser,
            sesPass: res?.sesPass,
            ftpDirPath: res?.ftpDirPath,
            lessorCode: res?.lessorCode,
            establishmentCode: res?.establishmentCode,
            suscription: res?.suscription === 'active',
            platform: res?.platform,
            isConfigLoaded: true,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },

    onCheckSESConnection: () => {
      updateState({ isLoading: true });
      API.policeReportCheckSESConnection({
        hotelId: getSelectedHotel()?.key,
        sesCredentials: {
          username: state.sesUser,
          password: state.sesPass,
          lessorCode: state.lessorCode,
          establishmentCode: state.establishmentCode,
        },
      })
        .then(result => {
          updateState({ isLoading: false });

          if (result.success) {
            notification.success({
              message: translate('policeReport.checkSESConnectionSuccess'),
            });
          } else {
            notification.error({
              message: translate('policeReport.checkSESConnectionError'),
            });
          }
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.checkSESConnectionError'),
          });
        });
    },

    onSaveSESConfig: () => {
      updateState({ isLoading: true });

      API.updatePoliceSES({
        hotelId: getSelectedHotel()?.key,
        sesCredentials: {
          sesCredentials: {
            user: state.sesUser,
            password: state.sesPass,
          },
          lessorCode: state.lessorCode,
          establishmentCode: state.establishmentCode,
        },
      })
        .then(_ => {
          API.policeReportCheckSESConnection({
            hotelId: getSelectedHotel()?.key,
            sesCredentials: {
              platform: 'ses',
              username: state.sesUser,
              password: state.sesPass,
              lessorCode: state.lessorCode,
              establishmentCode: state.establishmentCode,
            },
          })
            .then(result => {
              updateState({ isLoading: false });

              if (result.success) {
                notification.success({
                  message: translate('policeReport.checkSESConnectionSuccess'),
                });
              } else {
                notification.error({
                  message: translate('policeReport.checkSESConnectionError'),
                });
              }
            })
            .catch(err => {
              console.warn(err);
              updateState({ isLoading: false });
              notification.error({
                message: translate('policeReport.checkSESConnectionError'),
              });
            });
        })
        .catch(err => {
          //ERROR saving credentials
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.sesError'),
          });
        });
    },
    isAdmin: () => {
      return appState?.superUser ? true : false;
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
