import React, { useContext, useRef } from 'react';
import styles from './Forms.styles.js';
import useFormsHook from './Forms.hook.js';
import { AppContext } from 'App.context.js';
import { Select, Tooltip } from 'antd';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import SaveButton from 'Components/common/SaveButton/SaveButton.jsx';
import GenerateFormButton from 'Components/common/GenerateFormButton/GenerateFormButton.jsx';
import GuestForms from './GuestForms/GuestForms.jsx';
import DynamicForms from './DynamicForms/DynamicForms.jsx';
import Loading from 'Components/common/Loader/Loading.jsx';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator.jsx';
import Colors from 'constants/Colors.js';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function Forms() {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const { state, actions } = useFormsHook();
  const formRef = useRef();

  const languagesMenu = state.languages?.map(language => {
    return (
      <Select.Option key={language?.code} value={language?.code}>
        {`  ${language?.label}`}
      </Select.Option>
    );
  });

  return (
    <DefaultLayout
      singleBox
      title={translate('layout.forms')}
      contentHeader={
        <div style={styles.header}>
          <div>
            {state.languages && (
              <Select
                style={{ marginRight: 8 }}
                defaultValue={state.languages[0].code}
                value={state.selectedLanguage}
                onChange={option => actions.onLanguageSelected(option)}
              >
                {languagesMenu}
              </Select>
            )}
            {translate('guestTable.languageSelector')}
          </div>

          {appState.hotelList && appState.hotelList?.length && (
            <div style={styles.footer}>
              <SaveButton
                text={translate('save')}
                action={applyToAllHotels => {
                  if (formRef.current) {
                    formRef.current.saveClicked(applyToAllHotels);
                  }
                }}
                showPopConfirm
              />
              {state.isLoading && (
                <div style={{ marginLeft: 16 }}>
                  <Loading size="small" />
                </div>
              )}
            </div>
          )}
        </div>
      }
    >
      <div style={styles.headerMapping}>
        {['Cloudbeds', 'Cloudbeds_new', 'Opera', 'Ohip'].includes(state.pmsModuleName) && (
          <div style={styles.textMapping}>
            <DotIndicator color={Colors.yellowPill} />
            <div style={styles.textWithIcon}>
              {translate('guestTable.requiresMapping')}
              <Tooltip 
                title={translate('guestTable.requiresMappingDescription')} 
                overlayInnerStyle={styles.tooltip} 
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
        )}
        <div style={styles.textMapping}>
          <DotIndicator color={Colors.red} />
          <div style={styles.textWithIcon}>
            {translate('guestTable.unavailableInPMS')}
            <Tooltip 
              title={translate('guestTable.unavailableInPMSDescription')}
              overlayInnerStyle={styles.tooltip} 
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </div>
      </div>
      <div style={styles.container}>
        {state.isDynamicForm ? (
          state.form ? (
            <>
              <GenerateFormButton translate={translate} isLoading={state.isLoading} />
              <DynamicForms
                key={getSelectedHotel()?.key}
                formRef={formRef}
                isLoading={state.isLoading}
                selectedLanguage={state.selectedLanguage}
                form={state.form}
                onSave={actions.saveForm}
                languages={state.languages}
              />
            </>
          ) : (
            <div style={styles.dynamicEmpty}>
              <Loading />
            </div>
          )
        ) : (
          <>
            <GenerateFormButton translate={translate} />
            <GuestForms
              formRef={formRef}
              isLoading={state.isLoading}
              selectedLanguage={state.selectedLanguage}
              selectedHotelId={getSelectedHotel()?.key}
              languages={state.languages}
              onSave={actions.saveForm}
            />
          </>
        )}
      </div>
    </DefaultLayout>
  );
}
