import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './icomoon.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleAuthProvider } from './context/GoogleAuthContext';
import constants from 'utils/constants';

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={constants.GMAIL_CLIENT_ID}>
      <GoogleAuthProvider>
        <App />
      </GoogleAuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Mantén esto como estaba originalmente
serviceWorker.unregister();
