import Colors from "constants/Colors";

export default {
  labelTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#424242',
  },
  inputContainer: {
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'var(--color-light-gray)',
    color: 'var(--color-light-gray)',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: 32,
  },
  descriptionText: {
    margin: 0,
    fontSize: '12px',
    color: '#888',
  },
  activeStatus: {
    textAlign: 'center',
    color: Colors.greenHotelIntegratedText,
    backgroundColor: Colors.greenHotelIntegratedBg,
    borderRadius: 16,
    border: 0,
    width: 110,
    height: 22,
  },
  pendingStatus: {
    textAlign: 'center',
    color: Colors.yellowHotelNoIntegratedText,
    backgroundColor: Colors.yellowHotelNoIntegratedBg,
    borderRadius: 16,
    border: 0,
    width: 110,
    height: 22,
  },
  textContainerModal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hotelSelector: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  iconModal: {
    margin: '25px 0px',
    backgroundColor: Colors.redDesactivedBg,
    color: Colors.red,
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: {
    backgroundColor: Colors.blueIconBg,
    color: Colors.blueColor,
    borderColor: Colors.lightBlue,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
  editTitle: {
    fontSize: '18px',
  },
  editDescription: {
    fontSize: '12px',
    color: Colors.grayText,
    marginBottom: '16px',
  },
  separator: {
    height: '1px',
    backgroundColor: Colors.lightGray,
    marginTop: '24px',
    marginBottom: '16px',
  },
  editUserContainer: {
    borderRadius: '12px',
  },
  titleModal: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  textModal: {
    fontSize: '13px',
    color: '#333',
    textAlign: 'center',
    marginBottom: '20px',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '95%',
    borderRadius: '5px',
    backgroundColor: Colors.blueColor,
    borderColor: Colors.blueColor,
    marginBottom: '10px',
  },
  editButton: {
    width: '95%',
    borderRadius: '5px',
    backgroundColor: Colors.blueColor,
    borderColor: Colors.blueColor,
    marginBottom: '10px',
  },
  cancelButton: {
    width: '95%',
    borderRadius: '5px',
  },
  confirmButton: {
    width: '95%',
    borderRadius: '5px',
    backgroundColor: Colors.blueColor,
    borderColor: Colors.blueColor,
    marginBottom: '10px',
  },
};
