import { notification } from 'antd';
import { AppContext } from 'App.context';
import { act } from 'react';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';

export default function usePoliceReportMOSSOSConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [state, setState] = useState({
    isLoading: false,
    fileFormat: null,
    sesUser: null,
    sesPass: null,
    lessorCode: null,
    establishmentCode: null,
    suscription: null,
    sendSuscriptionRequestEnabled: true,
    platform: null,
  });

  useEffect(() => {
    actions.loadPoliceReportConfig();
  }, [appState.selectedHotel]);

  const actions = {
    loadPoliceReportConfig: () => {
      updateState({ isLoading: true });
      API.getPoliceConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          updateState({
            isLoading: false,
            fileFormat: res?.fileFormat,
            sesUser: res?.sesUser,
            sesPass: res?.sesPass,
            lessorCode: res?.lessorCode,
            establishmentCode: res?.establishmentCode,
            suscription: res?.suscription === 'active',
            platform: res?.platform,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        });
    },
    

    // TODO; COMPLETE CHECK CONNECTION FOR MOSSO PLATFFORM
    // onCheckSESConnection: () => {
    //   updateState({ isLoading: true });
    //   API.policeReportCheckSESConnection({
    //     hotelId: getSelectedHotel()?.key,
    //     sesCredentials: {
    //       username: state.sesUser,
    //       password: state.sesPass,
    //       lessorCode: state.lessorCode,
    //       establishmentCode: state.establishmentCode,
    //     },
    //   })
    //   .then(result => {
    //     updateState({ isLoading: false });

    //     if ( result.success ) {
    //       notification.success({
    //         message: translate('policeReport.checkSESConnectionSuccess'),
    //       });
    //     } else {
    //       notification.error({
    //         message: translate('policeReport.checkSESConnectionError'),
    //       });
    //     }
    //   })
    //   .catch(err => {
    //     console.warn(err);
    //     updateState({ isLoading: false });
    //     notification.error({
    //       message: translate('policeReport.checkSESConnectionError'),
    //     });
    //   });
    // },


    onSaveERTZConfig: () => {
      updateState({ isLoading: true });

      API.updatePoliceMOSSOS({
        hotelId: getSelectedHotel()?.key, 
        body: {
        establishmentCode: state.establishmentCode,
        }
      })
      .then((res) => {
        updateState({ isLoading: false });
        if (res.success) {
          notification.success({
            message: translate('policeReport.mossos.warnings.successSaving'),
          });
        } else {
          notification.error({
            message: translate('policeReport.mossos.warnings.errorSaving'),
          });
        }
      })
      
          // TODO: CHECK THE REQUEST TO CHECK THE CONNECTION
          // API.policeReportCheckSESConnection({
          //   hotelId: getSelectedHotel()?.key,
          //   sesCredentials: {
          //     username: state.sesUser,
          //     password: state.sesPass,
          //     lessorCode: state.lessorCode,
          //     establishmentCode: state.establishmentCode,
          //   },
          // })
          //   .then(result => {
          //     updateState({ isLoading: false });

          //     if (result.success) {
          //       notification.success({
          //         message: translate('policeReport.checkSESConnectionSuccess'),
          //       });
          //     } else {
          //       notification.error({
          //         message: translate('policeReport.checkSESConnectionError'),
          //       });
          //     }
          //   })
          //   .catch(err => {
          //     console.warn(err);
          //     updateState({ isLoading: false });
          //     notification.error({
          //       message: translate('policeReport.checkSESConnectionError'),
          //     });
          //   });
        .catch(err => {
          //ERROR saving credentials
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('policeReport.ertzError'),
          });
        });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },
    isAdmin: () => {
      return appState?.superUser ? true : false;
    }
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
